import React, { useState } from 'react';
import axios, { AxiosRequestConfig, CancelToken, Method, AxiosError } from 'axios';
import { Auth } from 'aws-amplify';
import { GET } from '../services/RequestMethods';
import { environment } from '../services/environment';
import store from 'utils/reduxStore';
import { logout } from '../actions/AuthActions';
import { doLogout } from '../actions';
import Cookies from 'js-cookie';

interface UseServiceProps<ResponseData = unknown> {
    url?: string;
    method?: Method;
    params?: Record<string, unknown>;
    defaultData?: Partial<ResponseData>;
    debug?: boolean;
    headers?: Record<string, string>;
    baseURL?: string;
    hasAuthedUrl?: boolean;
    formatResponseCallback?: (data: ResponseData) => ResponseData;
    entityResultsKey?: string;
    passthrough?: ResponseData;
}

interface FireProps {
    url?: string;
    params?: Record<string, unknown>;
    persist_changes?: boolean;
    hasAuthedUrl?: boolean;
    cancelToken?: CancelToken;
    hideShowLoading?: boolean;
    method?: Method;
    data?: unknown;
    headers?: Record<string, string>;
    baseURL?: string | undefined;
    onUploadProgress?: (progressEvent: ProgressEvent) => void;
}

export interface UseServiceReturn<ResponseData = unknown> {
    loading: boolean;
    data: ResponseData;
    error: AxiosError | Error | false;
    setData: React.Dispatch<React.SetStateAction<ResponseData>>;
}

export const requestCancelToken = () => {
    const source = axios.CancelToken.source();
    const cancelToken = source.token;
    const cancelCallback = source.cancel;

    return {
        cancelToken,
        cancelCallback,
    };
};

const isAxiosError = (error: unknown): error is AxiosError => {
    return Boolean(error) && 
        typeof error === 'object' && 
        'isAxiosError' in error && 
        error.isAxiosError === true;
};

export const useService = <ResponseData = Record<string, unknown>>(
    props: UseServiceProps<ResponseData> = {},
): [UseServiceReturn<ResponseData>, (fireProps?: FireProps) => Promise<ResponseData>] => {
    const {
        url,
        method = GET as Method,
        params = undefined,
        defaultData = {} as ResponseData,        
        debug = false,
        headers = {},
        baseURL = environment.apiEndPoint,
        hasAuthedUrl = false,
        formatResponseCallback,
        entityResultsKey = 'results',
        passthrough = false,
    } = props;

    const [data, setData] = useState<ResponseData>(defaultData as ResponseData);
    const [error, setError] = useState<AxiosError | Error | false>(false);    
    const [loading, setLoading] = useState(false);
    const dispatch = store().dispatch;

    const formatParams = (
        params: Record<string, unknown>, 
        _method: Method = method,
    ) => ({
        [_method === GET ? 'params' : 'data']: params,
    });

    const [config, setConfig] = useState<AxiosRequestConfig>({
        baseURL,
        method,
        headers: {
            ...{
                'x-api-key': environment.apiKey,
                'content-type': 'application/json',
            },
            ...headers,
        },
        ...formatParams(params),
    });

    const getBearerToken = async () => {
        try {
            const session = await Auth.currentSession();
            const jwtToken = session.getIdToken().getJwtToken();
            return jwtToken;
        } catch (e) {
            console.error('Error fetching token', e);
        }
    };

    const fire = async ({
        url: fireUrl = url,
        params: request_params = null,
        persist_changes = true,
        hasAuthedUrl: _hasAuthedUrl = hasAuthedUrl,
        cancelToken,
        hideShowLoading = false,
        ...config_override
    }: FireProps = {}) => {
        if (!fireUrl) {
            throw new Error('URL must be provided either during hook initialization or in fire call');
        }

        let _configs = { ...config, url: fireUrl };
        const jwtToken = await getBearerToken();

        if (jwtToken) {
            _configs.headers = {
                ..._configs.headers,
                Authorization: `Bearer ${jwtToken}`,
            };
        }

        if (_configs?.url?.includes('users/account') || _configs?.url?.includes('authed/')) {
            const stRegType = Cookies.get('st.reg_type');
            if (stRegType) {
                _configs.headers = {
                    ..._configs.headers,
                    'st-reg-type': stRegType,
                };
            }
        }

        if (config_override) {
            _configs = { ..._configs, ...config_override };
            persist_changes && setConfig(_configs);
        }

        if (request_params) {
            _configs = {
                ..._configs,
                ...formatParams(request_params, _configs.method as Method),
            };
            persist_changes && setConfig(_configs);
        }

        if (_hasAuthedUrl && _configs.headers?.Authorization) {
            _configs.url = `authed/${fireUrl}`;
        }

        setError(false);
        if (!hideShowLoading) {
            setLoading(true);
        }

        debug && console.info('useService.fire', { _configs });

        try {
            if (cancelToken) {
                _configs.cancelToken = cancelToken;
            }

            let responseData: ResponseData;
            if (passthrough) {
                responseData = passthrough;
            } else {
                const { data } = await axios(_configs);
                responseData = data;
            }
            debug && console.info('useService.fired.resolve', { data: responseData });

            if (formatResponseCallback && entityResultsKey) {
                responseData[entityResultsKey] = formatResponseCallback(
                    responseData[entityResultsKey],
                );
            }

            setData(responseData);
            setLoading(false);

            return responseData;
        } catch (err: unknown) {
            debug && console.info('useService.fired.reject', { err });

            if (isAxiosError(err)) {
                setError(err);
                if (err.response?.status === 401) {
                    try {
                        dispatch(logout());
                        dispatch(doLogout());
                    } catch (logoutError) {
                        console.error('Error during logout:', logoutError);
                    }
                }
                if (axios.isCancel(err) && err.message === 'canceled-new-call') {
                    // Cancelled due to a new call, no further action needed
                } else {
                    setLoading(false);
                }
            } else if (err instanceof Error) {
                setError(err);
                setLoading(false);
            }

            throw err;
        }
    };

    return [{ loading, data, error, setData }, fire];
};