import React, { useState } from 'react';
import Link from 'components/Link';
import { connect } from 'react-redux';
import { get } from 'lodash';
import StartsCloses from '../Details/StartsCloses';
import AuctionImage from '../AuctionImage';
import AuctionRibbon from '../AuctionRibbon';
import { formatted_number } from 'helpers/number';
import { get_user_datetime} from 'helpers/date';
import { cityStateToUrl } from 'helpers/auctions';
import TimerProvider from 'context/TickContext';
import moment from 'moment';
import { useRouter } from 'next/router';
import { useAuctionNavigation } from 'context/AuctionNavigationContext';

const AuctionTile = (props) => {
    const router = useRouter();
    const { setNavigatingToAuction } = useAuctionNavigation();
    
    const [isExpired, setIsExpired] = useState(false);
    const [validExpiredTime, setValidExpiredTime] = useState(true);
    
    const formatNumber = (number) => (parseInt(number) < 10 ? '0' : '') + number;

    const getTileImage = () => {
        // not small screen and a lg image is passed in then use the lg one
        const size = `image_path${ props.windowWidth <= 768 && get(props.auction.image, 'image_path') ? '_large' : ''}`;
        // 3rd param could be a missing icon or something
        return get(props.auction.image, size);
    };

    const renderAmountSection = () => {
        const {
            upcoming = false, current_bid, initial_bid, total_bids = 0,
        } = props.auction;

        switch (props.auction.status_id) {
            case '1': // active
                if (!upcoming) {
                    if (total_bids > 0) {
                        return (
                            <div className="item clock-container">
                                High Bid:
                                <div className="auction-tile-high-bid tile-amount">
                                    {formatted_number(current_bid)}
                                </div>
                            </div>
                        );
                    }
                    return (
                        <div className="item clock-container">
                            Minimum Bid:
                            <div className="auction-tile-high-bid tile-amount">
                                {formatted_number(initial_bid)}
                            </div>
                        </div>
                    );
                }

                return (
                    <div className="item clock-container">
                        Minimum Bid:
                        <div className="auction-tile-high-bid tile-amount">
                            {formatted_number(initial_bid)}
                        </div>
                    </div>
                );
            case '2': // sold
            case '3': // unsold
            case '4': // canceled
                return (
                    <div className="item clock-container">
                        Final High Bid:
                        <div className="auction-tile-high-bid tile-amount">
                            {formatted_number(current_bid)}
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    const handleExpiredTime = () => {
        setIsExpired(true);
        setValidExpiredTime(true);
        props.onExpired && props.onExpired(true, true);
    };

    const renderTimer = () => {
        const {
            current_date: currentDate,
        } = props.auction || {};

        return (
            <TimerProvider currentDate={parseInt(moment(get_user_datetime(currentDate)) + '')}>
                <StartsCloses
                    auction={props.auction}
                    handleExpiredTime={handleExpiredTime}
                    auctionTile
                />
            </TimerProvider>
        );
    };

    const chompContents = (vertical) => {
        const LGCharsPerLine = 50;
        const MDCharsPerLine = 45;
        const max_lines = 2;

        // horizontal char limit
        let charLimit = props.windowWidth >= 1200 ? LGCharsPerLine * max_lines : MDCharsPerLine * max_lines;

        if (vertical) {
            charLimit = props.windowWidth >= 992 ? 166 : 80;
        }

        if (props.auction && props.auction.unit_contents) {
            const doubleTest = props.auction.unit_contents;
            const add_ellipsis = doubleTest.length > charLimit ? '...' : '';
            return doubleTest.slice(0, charLimit) + add_ellipsis;
        }

        return '';
    };

    const renderAppearsToContain = (vertical) => {
        const showContents = get(props, 'showContents', true);
        if (showContents == true && showContents !== undefined) {
            return (
                <div className="unit-contains">
                    <h3 className="contains-label">
                        Unit Appears to Contain:
                    </h3>
                    <div className="text">
                        {chompContents(vertical)}
                    </div>
                </div>
            );
        }

        return null;
    };

    const navigateToAuction = (path) => {
        setNavigatingToAuction(true);
        window.scrollTo(0, 0);
        router.push(path);
    };

    const renderHorizontalTile = () => {
        let auctionTileClass = 'auction-tile horizontal-tile';

        if (validExpiredTime && isExpired) {
            auctionTileClass += ' expired';
        }

        if (!validExpiredTime) {
            auctionTileClass += ' invalid-time';
        }

        const {
            auction_id, unit_width, city, state, facility_name, unit_length,
        } = props.auction;

        const cityStateUrl = cityStateToUrl({state, city});

        const linkContent = (
            <>
                <div className="section tile-image">
                    <AuctionImage
                        auction={props.auction}
                        image_url={getTileImage()}
                    />
                </div>
                <div className="section tile-countdown">
                    <div className="tile-bid-amount">
                        {renderAmountSection(false)}
                    </div>
                    <div className="tile-counter">
                        {renderTimer()}
                    </div>
                </div>
                <div className="section tile-details">
                    <h3 className="facility-name">{facility_name}</h3>
                    <div className="city-state">
                        {`${city}, ${state}`}
                        <br />
                    </div>
                    <div className="unit-size">
                        Approx. Size:
                        {' '}
                        {`${unit_width} x ${unit_length}`}
                    </div>
                    {renderAppearsToContain(false)}
                </div>
            </>
        );

        if (props.otherAuctions) {
            return (
                <div
                    onClick={() => navigateToAuction(`/auctions/${cityStateUrl}/${auction_id}`)}
                    id={`auction-tile-${auction_id}`}
                    className={`${auctionTileClass} cursor-pointer`}
                >
                    {linkContent}
                </div>
            );
        } else {
            return (
                <Link to={`/auctions/${cityStateUrl}/${auction_id}`} id={`auction-tile-${auction_id}`} className={auctionTileClass}>
                    {linkContent}
                </Link>
            );
        }
    };

    const renderVerticalTile = () => {
        const { auction } = props;

        let auctionTileClass = 'auction-tile vertical-tile';

        if (validExpiredTime && isExpired) {
            auctionTileClass += ' expired';
        }

        if (!validExpiredTime) {
            auctionTileClass += ' invalid-time';
        }

        const {
            auction_id, unit_width, city, state, facility_name, unit_length,
        } = auction;

        const cityStateUrl = cityStateToUrl({state, city});

        const linkContent = (
            <>
                <div className="section tile-image">
                    <AuctionImage
                        auction={auction}
                        image_url={getTileImage()}
                        needs_bottom={false}
                    />
                </div>
                <div className="section tile-auction-status">
                    <AuctionRibbon position="bottom" auction={auction} />
                </div>
                <div className="section tile-countdown">
                    <div className="tile-bid-amount">
                        {renderAmountSection(true)}
                    </div>
                    <div className="tile-counter">
                        {/* auctions only show countdown if the auction is active */}
                        {props.auction.status_id == 1 && renderTimer()}
                    </div>
                </div>
                <div className="section tile-details">
                    <h3 className="facility-name">{facility_name}</h3>
                    <div className="city-state">
                        {`${city}, ${state}`}
                        <br />
                    </div>
                    <div className="unit-size">
                        Approx. Size:
                        {' '}
                        {`${unit_width} x ${unit_length}`}
                    </div>
                    {renderAppearsToContain(true)}
                </div>
            </>
        );

        if (props.otherAuctions) {
            return (
                <div
                    onClick={() => navigateToAuction(`/auctions/${cityStateUrl}/${auction_id}`)}
                    id={`auction-tile-${auction_id}`}
                    className={`${auctionTileClass} cursor-pointer`}
                >
                    {linkContent}
                </div>
            );
        } else {
            return (
                <Link to={`/auctions/${cityStateUrl}/${auction_id}`} id={`auction-tile-${auction_id}`} className={auctionTileClass}>
                    {linkContent}
                </Link>
            );
        }
    };

    if (props.allowHorizontal && props.windowWidth >= 992) {
        return renderHorizontalTile();
    }

    return renderVerticalTile();
};

AuctionTile.defaultProps = {
    allowHorizontal: false,
    onExpired(validTimer = true, isExpired = false) {
        // This is a callback that fires as soon as the time is expired
    },
    auction: {},
    otherAuctions: false,
};

const mapStateToProps = ({ page }) => ({
    windowWidth: page.windowWidth,
});

export default connect(mapStateToProps)(AuctionTile);