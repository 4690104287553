import React, { createContext, useContext, useState, ReactNode } from 'react';

interface AuctionNavigationContextType {
    navigatingToAuction: boolean;
    setNavigatingToAuction: (navigating: boolean) => void;
}

const AuctionNavigationContext = createContext<AuctionNavigationContextType>({
    navigatingToAuction: false,
    setNavigatingToAuction: () => {},
});

export const useAuctionNavigation = () => useContext(AuctionNavigationContext);

interface AuctionNavigationProviderProps {
    children: ReactNode;
}

export const AuctionNavigationProvider: React.FC<AuctionNavigationProviderProps> = ({ children }) => {
    const [navigatingToAuction, setNavigatingToAuction] = useState(false);

    return (
        <AuctionNavigationContext.Provider
            value={{
                navigatingToAuction,
                setNavigatingToAuction,
            }}
        >
            {children}
        </AuctionNavigationContext.Provider>
    );
};