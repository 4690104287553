import React, { forwardRef, ReactNode } from 'react';
import { Form } from 'react-bootstrap';
import type { FormControlProps } from 'react-bootstrap/FormControl';
import HelpBlock from '../HelpBlock';
import './styles.scss';

interface SelectProps extends FormControlProps {
    label?: string;
    name: string;
    children: ReactNode;
    addBlank?: boolean;
    blankLabel?: string;
    help?: string | boolean;
    error?: string;
    containerClassName?: string;
    feedbackClassName?: string;
    variant?: string | null;
}

const Select = forwardRef<HTMLSelectElement, SelectProps>((props, ref) => {
    const {
        label,
        name,
        children,
        addBlank = false,
        blankLabel = 'Please Select',
        help = false,
        error,
        className = '',
        containerClassName = '',
        feedbackClassName = '',
        variant = null,
        ...rest
    } = props;

    const fullClassName = className + (variant ? ` select-${variant}` : '');

    return (
        <Form.Group className={`${containerClassName} ${error && 'invalid-feedback-container'}`} controlId={`form${name}Dropdown`}>
            {label && <Form.Label>{label}</Form.Label>}
            <div style={{ position: 'relative' }}>
                <Form.Control
                    as="select"
                    name={name}
                    ref={ref}
                    isInvalid={!!error}
                    className={fullClassName}
                    {...rest}
                >
                    {addBlank && (<option value="" hidden>{blankLabel}</option>)}
                    {children}
                </Form.Control>
            </div>
            <HelpBlock help={help} />
            {error && (
                <Form.Control.Feedback type="invalid" className={feedbackClassName}>
                    {error}
                </Form.Control.Feedback>
            )}
        </Form.Group>
    );
});

Select.displayName = 'Select';

export default Select;