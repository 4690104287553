import React, { useEffect, useState } from 'react';
import NextHead from 'next/head';
import { has } from 'lodash';
import { useSelector } from 'react-redux';
import { usePage } from 'context/PageContext';
import { APP_CONFIG } from 'Config';
import { useService } from 'hooks/useService';

const PageHead = ({ route_path }) => {
    const { pageData } = usePage();
    const [facilityDescription, setFacilityDescription] = useState('');
    const [{ data: facilityData }, getFacilityData] = useService({
        defaultData: { facility: { city: '' } },
    });
    
    const reduxPageData = useSelector(state => state.page);

    const checkRender = (key, toRender, secondary_condition = true, default_value = null) => 
        (secondary_condition && has(pageData, key) ? toRender : default_value);

    useEffect(() => {
        if (route_path.includes('facilities')) {
            const facilityId = route_path.replace(/\D/g, '');
            if (facilityId) {
                getFacilityData({
                    url: `facilities/${facilityId}`,
                });
            }
        }
    }, [route_path]);

    useEffect(() => {
        if (facilityData?.facility?.city) {
            setFacilityDescription(
                `Storage unit auctions are happening now at a self storage facility in ${facilityData.facility.city}. Explore active auction details and photos, then start bidding & winning!`,
            );
        }
    }, [facilityData]);

    // Prefer context data, fall back to Redux data
    const title = pageData.title || reduxPageData.title || '';
    const description = pageData.description || reduxPageData.description || '';
    const keywords = 'self storage auction, self storage auctions, storage auction, storage auctions, storage auctions usa, storage shed auctions, storage unit auction, storage unit auctions, storage units auction, storage auction information';
    
    const og = pageData.og || {};

    const {
        image: ogImage,
        description: ogDescription = description,
    } = og;

    return (
        <NextHead>
            <title>{title || 'StorageTreasures.com'}</title>
            <meta 
                name="description" 
                content={description || facilityDescription || 'A free self storage auction directory offering real time auction listings, alerts, tools, how-to resources and more. The ultimate resource for storage auctions.'} 
            />
            <meta name="keywords" content={keywords} />

            <meta property="og:title" content={title} />
            <meta property="og:description" content={ogDescription} />
            <meta property="og:type" content="article" />
            <meta property="og:image:type" content="image/jpeg" />

            {checkRender('og.image',
                <>
                    <meta property="og:image" content={`${ogImage}`} />
                    <meta property="og:image_url" content={`${ogImage}`} />
                    <meta property="og:image:secure_url" content={`${ogImage}`} />
                </>,
            )}

            <meta property="og:image" content={`${APP_CONFIG.APP_URL}/static/images/logo_white_bg_social_media.jpg`} />
            <meta property="og:image_url" content={`${APP_CONFIG.APP_URL}/static/images/logo_white_bg_social_media.jpg`} />
            <meta property="og:image:secure_url" content={`${APP_CONFIG.APP_URL}/static/images/logo_white_bg_social_media.jpg`} />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:image:alt" content={ogDescription} />
            <meta name="twitter:image:alt" content={title} />

            <meta name="loading" content="no" data-description="If an api call is being performed" />
            <meta name="loading_upload_percent" content="100%" data-description="File upload progress" />
        </NextHead>
    );
};

export default PageHead;