import React, { createContext, useState, useContext, ReactNode } from 'react';

interface BidBanner {
    type: string;
    subject: string;
    body: string;
    buttonText: string | boolean;
    buttonLink: string | boolean;
}

type BidNotification = Partial<BidBanner> | false;

interface BidNotificationContextType {
    bidBanner: BidBanner;
    handleBidBanner: (notification?: BidNotification) => void;
}

const BidNotificationContext = createContext<BidNotificationContextType>({
    bidBanner: {
        type: '',
        subject: '',
        body: '',
        buttonText: false,
        buttonLink: false,
    },
    handleBidBanner: () => {},
});

interface BidNotificationProviderProps {
    children: ReactNode;
}

export const BidNotificationProvider = ({ children }: BidNotificationProviderProps) => {
    const [bidBanner, setBidBanner] = useState<BidBanner>({
        type: '',
        subject: '',
        body: '',
        buttonText: false,
        buttonLink: false,
    });

    const handleBidBanner = (notification: BidNotification = false) => {
        if (!notification) {
            setBidBanner({
                type: '',
                subject: '',
                body: '',
                buttonText: false,
                buttonLink: false,
            });
        } else {
            setBidBanner({
                type: notification.type || '',
                subject: notification.subject || '',
                body: notification.body || '',
                buttonText: notification.buttonText || false,
                buttonLink: notification.buttonLink || false,
            });
        }
    };

    return (
        <BidNotificationContext.Provider value={{ bidBanner, handleBidBanner }}>
            {children}
        </BidNotificationContext.Provider>
    );
};

export const useBidNotification = () => useContext(BidNotificationContext);