import React from 'react';
import BaseInput from './BaseInput';
import { Field, FieldInputProps, FormikProps, FormikErrors, FormikTouched } from 'formik';

type BaseInputProps = React.ComponentProps<typeof BaseInput>

interface CustomInputProps {
    formik?: boolean
    nested?: boolean
    forceValidation?: boolean
    onChange?: (data: { value: string; [key: string]: any }, event: React.ChangeEvent<HTMLInputElement>) => void
    onBlur?: (data: { value: string; [key: string]: any }, event: React.FocusEvent<HTMLInputElement>) => void
    onIconClick?: (e?: React.MouseEvent<HTMLElement>) => void
    prependComponent?: React.ReactNode
    value?: string | number
}

type InputProps = CustomInputProps & Omit<BaseInputProps, 'onChange' | 'value' | 'onBlur'>

type FormValues = Record<string, any>

function isFormikTouched(touched: boolean | FormikTouched<any> | FormikTouched<any>[] | undefined): touched is FormikTouched<any> {
    return typeof touched === 'object' && !Array.isArray(touched);
}

function isFormikErrors(errors: string | string[] | FormikErrors<any> | FormikErrors<any>[] | undefined): errors is FormikErrors<any> {
    return typeof errors === 'object' && !Array.isArray(errors);
}

export const Input: React.FC<InputProps> = ({ formik = false, onChange, onBlur, nested = false, forceValidation = false, ...props }) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
            const value = e.target.value;
            const { value: _, ...restProps } = props;
            onChange({ value, ...restProps as { [key: string]: any } }, e);
        }
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        if (onBlur) {
            const value = e.target.value;
            const { value: _, ...restProps } = props;
            onBlur({ value, ...restProps as { [key: string]: any } }, e);
        }
    };

    const convertValue = (value: any): string => {
        if (value == null) return '';
        return String(value);
    };

    if (formik) {
        return (
            <Field name={props.name}>
                {({ field, form }: { field: FieldInputProps<any>; form: FormikProps<FormValues> }) => {
                    const fieldGroup = props.name.split('.')[0];
                    const fieldName = props.name.split('.')[1];

                    let touched = false;
                    let errorString: string | undefined;

                    if (nested) {
                        if (isFormikTouched(form.touched) && isFormikTouched(form.touched[fieldGroup])) {
                            touched = !!form.touched[fieldGroup][fieldName];
                        }
                        if (isFormikErrors(form.errors) && isFormikErrors(form.errors[fieldGroup])) {
                            errorString = form.errors[fieldGroup][fieldName] as string | undefined;
                        }
                    } else {
                        if (isFormikTouched(form.touched)) {
                            touched = !!form.touched[props.name];
                        }
                        if (isFormikErrors(form.errors)) {
                            errorString = form.errors[props.name] as string | undefined;
                        }
                    }

                    const baseInputProps: BaseInputProps = {
                        ...props,
                        ...field,
                        error: touched ? errorString : (forceValidation ? errorString : undefined),
                        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                            field.onChange(e);
                            handleChange(e);
                        },
                        onBlur: (e: React.FocusEvent<HTMLInputElement>) => {
                            field.onBlur(e);
                            handleBlur(e);
                        },
                        value: convertValue(field.value),
                        step: props.step != null ? String(props.step) : undefined,
                    };

                    return <BaseInput {...baseInputProps} />;
                }}
            </Field>
        );
    }

    const baseInputProps: BaseInputProps = {
        ...props,
        onChange: handleChange,
        value: convertValue(props.value),
        step: props.step != null ? String(props.step) : undefined,
    };

    return <BaseInput {...baseInputProps} />;
};
