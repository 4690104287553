import {
    CHANGE_MEMBERSHIP,
    CHANGE_MEMBERSHIP_FAIL,
    CHANGE_MEMBERSHIP_SUCCESS,
    CHECK_USER_DUPLICATE_EMAIL, CHECK_USER_DUPLICATE_PHONE,
    CREATE_USER_NOTIFICATION,
    CREATE_USER_NOTIFICATION_FAIL,
    CREATE_USER_NOTIFICATION_SUCCESS,
    DELETE_USER_BOOKMARKED_FACILITY,
    DELETE_USER_BOOKMARKED_FACILITY_FAIL,
    DELETE_USER_BOOKMARKED_FACILITY_SUCCESS,
    DELETE_USER_NOTIFICATION,
    DELETE_USER_NOTIFICATION_FAIL,
    DELETE_USER_NOTIFICATION_SUCCESS,
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_FAIL,
    FORGOT_PASSWORD_SUCCESS,
    GET_HEARD_QUESTIONS,
    GET_SECURITY_QUESTIONS,
    GET_USER_AUCTION_WATCH,
    GET_USER_AUCTION_WATCH_FAIL,
    GET_USER_AUCTION_WATCH_SUCCESS,
    GET_USER_AUCTIONS_WITH_BIDS,
    GET_USER_AUCTIONS_WITH_BIDS_FAIL,
    GET_USER_AUCTIONS_WITH_BIDS_SUCCESS,
    GET_USER_BOOKMARKED_FACILITIES,
    GET_USER_BOOKMARKED_FACILITIES_FAIL,
    GET_USER_BOOKMARKED_FACILITIES_SUCCESS,
    GET_USER_NOTIFICATION,
    GET_USER_NOTIFICATION_FAIL,
    GET_USER_NOTIFICATION_SUCCESS,
    GET_USER_NOTIFICATIONS,
    GET_USER_NOTIFICATIONS_FAIL,
    GET_USER_NOTIFICATIONS_SUCCESS,
    GET_WON_AUCTIONS,
    GET_WON_AUCTIONS_FAIL,
    GET_WON_AUCTIONS_SUCCESS,
    NOTIFICATIONS_FORM_CLEAR,
    NOTIFICATIONS_INPUT_CHANGED,
    REMOVE_USER_WATCHED_AUTION,
    REMOVE_USER_WATCHED_AUTION_FAIL,
    REMOVE_USER_WATCHED_AUTION_SUCCESS,
    SET_FORMATTED_TABLE_DATA,
    SETTINGS_FORM_CLEAR,
    SETTINGS_INPUT_CHANGED,
    UPDATE_USER_NOTIFICATION,
    UPDATE_USER_NOTIFICATION_FAIL,
    UPDATE_USER_NOTIFICATION_SUCCESS,
    UPGRADE_FORM_CLEAR,
    UPGRADE_INPUT_FORM_CHANGE,
    USER_BID_BANNER,
    USER_CHECKING_PROMO_CODE,
    USER_CHECKING_PROMO_CODE_FAIL,
    USER_CHECKING_PROMO_CODE_SUCCESS,
    USER_LOGIN,
    USER_LOGOUT,
    USER_PERSIST_LOGIN,
    USER_SETTINGS_GET,
    USER_SETTINGS_GET_FAIL,
    USER_SETTINGS_GET_SUCCESS,
    USER_SETTINGS_PAYMENT_METHOD,
    USER_SETTINGS_SAVE,
    USER_SETTINGS_SAVE_FAIL,
    USER_SETTINGS_SAVE_SUCCESS,
} from 'actions/UserTypes';

import {
    ACTIVE_AUCTIONS_INPUT_CHANGED,
    ACTIVE_SEARCH_INPUT_CHANGED,
    CANCELED_AFTER_SOLD_AUCTIONS_INPUT_CHANGED,
    CANCELED_AFTER_SOLD_SEARCH_INPUT_CHANGED,
    CANCELED_AUCTIONS_INPUT_CHANGED,
    CANCELED_SEARCH_INPUT_CHANGED,
    CUSTOM_DATE_INPUT_CHANGED,
    DRAFT_AUCTIONS_INPUT_CHANGED,
    DRAFT_SEARCH_INPUT_CHANGED,
    FACILITIES_SEARCH_INPUT_CHANGED,
    GET_FM_ACTIVE_AUCTIONS,
    GET_FM_ACTIVE_AUCTIONS_FAIL,
    GET_FM_ACTIVE_AUCTIONS_SUCCESS,
    GET_FM_CANCELED_AFTER_SOLD_AUCTIONS,
    GET_FM_CANCELED_AFTER_SOLD_AUCTIONS_FAIL,
    GET_FM_CANCELED_AFTER_SOLD_AUCTIONS_SUCCESS,
    GET_FM_CANCELED_AUCTIONS,
    GET_FM_CANCELED_AUCTIONS_FAIL,
    GET_FM_CANCELED_AUCTIONS_SUCCESS,
    GET_FM_DRAFT_AUCTIONS,
    GET_FM_DRAFT_AUCTIONS_FAIL,
    GET_FM_DRAFT_AUCTIONS_SUCCESS,
    GET_FM_FACILITIES,
    GET_FM_FACILITIES_FAIL,
    GET_FM_FACILITIES_SUCCESS,
    GET_FM_IMPORTED_AUCTIONS,
    GET_FM_IMPORTED_AUCTIONS_FAIL,
    GET_FM_IMPORTED_AUCTIONS_SUCCESS,
    GET_FM_LIVE_AUCTIONS,
    GET_FM_LIVE_AUCTIONS_FAIL,
    GET_FM_LIVE_AUCTIONS_SUCCESS,
    GET_FM_SOLD_AUCTIONS,
    GET_FM_SOLD_AUCTIONS_FAIL,
    GET_FM_SOLD_AUCTIONS_SUCCESS,
    GET_FM_UNSOLD_AUCTIONS,
    GET_FM_UNSOLD_AUCTIONS_FAIL,
    GET_FM_UNSOLD_AUCTIONS_SUCCESS,
    IMPORTED_SEARCH_INPUT_CHANGED,
    LIVE_AUCTIONS_INPUT_CHANGED,
    LIVE_SEARCH_INPUT_CHANGED,
    SOLD_AUCTIONS_INPUT_CHANGED,
    SOLD_SEARCH_INPUT_CHANGED,
    UNSOLD_AUCTIONS_INPUT_CHANGED,
    UNSOLD_SEARCH_INPUT_CHANGED,
} from 'actions/FacilityManagerTypes';

import { get, has, set } from 'lodash';
import { get_reg_type, is_authed_cache } from 'helpers/user';

import storage from 'helpers/storage';
import { general_success_toastr } from 'components/partials/Notification';

const INITIAL_STATE = {
    loading: true,
    loading_bookmarks: true,
    error: null,

    user_id: '',

    //TODO: when login is done swap this out with the new key
    reg_type: get_reg_type(),
    reg_type_label: get_reg_type('Not Set', true),

    payment_method: '',

    authed: false,
    has_imports: false,

    user: {
        reg_type: false,

        user_id: '',
        email: '',

        authed: is_authed_cache(),
        has_imports: false,

        timezone: 'America/Phoenix',
    },

    isDuplicateEmail: false,

    heard_about_us_loading: false,
    heard_about_us_loading_error: null,
    heard_about_us: [],

    security_questions_loading: false,
    security_questions_loading_error: null,
    security_questions: [],

    user_notifications_loading: false,
    user_notifications_loading_error: null,
    user_notifications: [],

    user_notification_loading: false,
    user_notification_loading_error: null,

    user_notification_days_list: [
        {
            label: 'SUN',
            key: 'sunday',
            name: 'notification_sunday',
        },
        {
            label: 'MON',
            key: 'monday',
            name: 'notification_monday',
        },
        {
            label: 'TUE',
            key: 'tuesday',
            name: 'notification_tuesday',
        },
        {
            label: 'WED',
            key: 'wednesday',
            name: 'notification_wednesday',
        },
        {
            label: 'THU',
            key: 'thursday',
            name: 'notification_thursday',
        },
        {
            label: 'FRI',
            key: 'friday',
            name: 'notification_friday',
        },
        {
            label: 'SAT',
            key: 'saturday',
            name: 'notification_saturday',
        },
    ],
    user_notification_form_changed: false,
    user_notification: {
        notification_id: 0,
        zipcode: '',
        radius: '25',
        sunday: ' 0',
        monday: '0',
        tuesday: '0',
        wednesday: '0',
        thursday: '0',
        friday: '0',
        saturday: '0',
    },
    notification_zipcode: '',
    notification_radius: '25',
    notification_sunday: '0',
    notification_monday: '0',
    notification_tuesday: '0',
    notification_wednesday: '0',
    notification_thursday: '0',
    notification_friday: '0',
    notification_saturday: '0',
    notification_checked: {},

    // Used so we can know if the user has started to edit the form. Maybe eventually we could utilize
    // this variable to confirm if the user wants to navigate away
    settings_form_changed: false,

    settings: {
        user_id: null,
        membership_type: 'Basic Bidder',
    },
    settings_loading: false,
    settings_error: null,

    // Password request workflow
    password_loading: false,
    password_error: false,
    password_request_done: false,

    watchlist: [],
    watchlist_loading: true,
    won_auctions: [],
    won_auctions_error: null,
    won_auctions_loading: false,
    won_auctions_headers: [false, {body: 'FINAL BID', className: 'number'}, 'FACILITY', 'UNIT', 'DATE WON', {body: 'AUCTION ID', className: 'number'}, 'TOTAL BIDS', 'TOTAL VIEWS'],

    formatted_table_data: [],

    loading_auction_bids: true,
    auction_bids: [],
    auction_bids_total_records: 0,

    upgrade_plan: 'monthly',
    promo_code: '',
    upgrade_loading: false,
    upgrade_error: '',
    upgrade_success: {},

    auction_bid_loading: false,
    auction_bid_error: false,

    last_bid: false,
    bid_banner: {},

    promo_valid: false,
    promo_loading: false,
    promo_error: false,

    fm_active_auctions: [],
    fm_active_auctions_count: 0,
    fm_active_error: false,
    fm_active_loading: true,
    fm_active_term: '',
    date_filter_selected: {
        date: 'any',
        date_label: 'Any Time',
    },

    fm_sold_auctions: [],
    fm_sold_auctions_count: 0,
    fm_sold_error: false,
    fm_sold_loading: true,
    fm_sold_term: '',

    fm_unsold_auctions: [],
    fm_unsold_auctions_count: 0,
    fm_unsold_error: false,
    fm_unsold_loading: true,
    fm_unsold_term: '',

    fm_canceled_auctions: [],
    fm_canceled_auctions_count: 0,
    fm_canceled_error: false,
    fm_canceled_loading: true,
    fm_canceled_term: '',

    fm_canceled_after_sold_auctions: [],
    fm_canceled_after_sold_auctions_count: 0,
    fm_canceled_after_sold_error: false,
    fm_canceled_after_sold_loading: true,
    fm_canceled_after_sold_term: '',

    fm_draft_auctions: [],
    fm_draft_auctions_count: 0,
    fm_draft_error: false,
    fm_draft_loading: true,
    fm_draft_term: '',

    fm_imported_auctions: [],
    fm_imported_auctions_count: 0,
    fm_imported_error: false,
    fm_imported_loading: true,
    fm_imported_term: '',

    fm_facilities: [],
    fm_facilities_count: 0,
    fm_facilities_error: false,
    fm_facilities_loading: true,
    fm_facilities_term: '',

    fm_live_auctions: [],
    fm_live_auctions_count: 0,
    fm_live_error: false,
    fm_live_loading: true,
    fm_live_term: '',
};

export default (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case CHECK_USER_DUPLICATE_EMAIL:
            return {...state, isDuplicateEmail: action.isDuplicateEmail};

        case CHECK_USER_DUPLICATE_PHONE:
            return {...state, isDuplicatePhone: action.isDuplicatePhone};

        case GET_HEARD_QUESTIONS:
            return {...state, heard_questions: [], heard_about_us_loading: true, heard_about_us_loading_error: null};
        case GET_HEARD_QUESTIONS + '_SUCCESS':
            return {...state, heard_questions: get(action, 'heard_about_us', []), heard_about_us_loading: false, heard_about_us_loading_error: null};
        case GET_HEARD_QUESTIONS + '_FAIL':
            return {...state, heard_about_us_loading: false, heard_about_us_loading_error: action.payload};

        case GET_SECURITY_QUESTIONS:
            return {...state, security_questions: [], security_questions_loading: true, security_questions_loading_error: null};
        case GET_SECURITY_QUESTIONS + '_SUCCESS':
            return {...state, security_questions: get(action, 'security_questions', []), security_questions_loading: false, security_questions_loading_error: null};
        case GET_SECURITY_QUESTIONS + '_FAIL':
            return {...state, security_questions_loading: false, security_questions_loading_error: action.payload};
        case CREATE_USER_NOTIFICATION:
            return {...state, user_notification: null, user_notifications_loading: true};
        case CREATE_USER_NOTIFICATION_SUCCESS:
            general_success_toastr({message: 'Notification saved', title: 'Success!'});
            state.user_notifications.push(action.user_notification);
            return {...state, user_notifications_loading: false};
        case CREATE_USER_NOTIFICATION_FAIL:
            return {...state, user_notification: {}, user_notifications_loading: false, user_notification_loading_error: action.payload};
        case GET_USER_NOTIFICATION:
            return {...state, user_notification: {}, user_notification_loading: true};
        case GET_USER_NOTIFICATION_SUCCESS:

            let {
                sunday: notification_sunday = '0',
                monday: notification_monday = '0',
                tuesday: notification_tuesday = '0',
                wednesday: notification_wednesday = '0',
                thursday: notification_thursday = '0',
                friday: notification_friday = '0',
                saturday: notification_saturday = '0',

                zipcode: notification_zipcode = null,
                radius: notification_radius = null,
            } = get(action, 'user_notification.auction_notification', {});

            state.notification_checked = {
                notification_sunday: notification_sunday == '1',
                notification_monday: notification_monday == '1',
                notification_tuesday: notification_tuesday == '1',
                notification_wednesday: notification_wednesday == '1',
                notification_thursday: notification_thursday == '1',
                notification_friday: notification_friday == '1',
                notification_saturday: notification_saturday == '1',
            };

            return {
                ...state,
                ...state.notification_checked,

                notification_zipcode,
                notification_radius,

                user_notification: get(action, 'user_notification.auction_notification', null),
                user_notification_loading: false,
            };

        case GET_USER_NOTIFICATION_FAIL:
            return {...state, user_notification: {}, user_notification_loading: false, user_notification_loading_error: action.payload};
        case GET_USER_NOTIFICATIONS:
            return {...state, user_notifications: [], user_notifications_loading: true};
        case GET_USER_NOTIFICATIONS_SUCCESS:
            return {...state, user_notifications: get(action, 'user_notifications', []), user_notifications_loading: false};
        case GET_USER_NOTIFICATIONS_FAIL:
            return {...state, user_notifications: {}, user_notifications_loading: false, user_notifications_loading_error: action.payload};
        case UPDATE_USER_NOTIFICATION:
            return {...state, user_notification: {}, user_notifications_loading: true};
        case UPDATE_USER_NOTIFICATION_SUCCESS:
            general_success_toastr({message: 'Notification updated', title: 'Success!'});
            const edited = state.user_notifications.find(notification => notification.notification_id == action.user_notification.notification_id),
                edit_index = state.user_notifications.indexOf(edited);

            state.user_notifications[edit_index] = action.user_notification;

            return {...state, user_notifications_loading: false};
        case UPDATE_USER_NOTIFICATION_FAIL:
            return {...state, user_notification: {}, user_notification_loading: false, user_notification_loading_error: action.payload};
        case DELETE_USER_NOTIFICATION:
            return {...state, user_notification: {}, user_notification_loading: true};
        case DELETE_USER_NOTIFICATION_SUCCESS:
            general_success_toastr({message: 'Notification deleted', title: 'Success!'});
            const deleted = state.user_notifications.find(notification => notification.notification_id == action.notification_id),
                index = state.user_notifications.indexOf(deleted);

            state.user_notifications.splice(index, 1);

            return {...state, user_notifications: state.user_notifications, user_notification_loading: false};
        case DELETE_USER_NOTIFICATION_FAIL:
            return {...state, user_notification: null, user_notification_loading: false, user_notification_loading_error: action.payload};

        case GET_USER_AUCTIONS_WITH_BIDS:
            return {...state, loading_auction_bids: true, error: null, auction_bids: [], auction_bids_total_records: 0};
        case GET_USER_AUCTIONS_WITH_BIDS_SUCCESS:
            return {...state, loading_auction_bids: false, error: false, auction_bids: get(action, 'payload.auctions', []), auction_bids_total_records: get(action, 'payload.total_records', 0)};
        case GET_USER_AUCTIONS_WITH_BIDS_FAIL:
            return {...state, loading_auction_bids: false, error: action.payload};

        case SETTINGS_FORM_CLEAR:
            return {...state, settings: {}, isDuplicateEmail: false, settings_form_changed: false};
        case SETTINGS_INPUT_CHANGED:
            // initialize and/or set the form value
            set(state.settings, action.name, action.value);
            return {...state};
        case USER_PERSIST_LOGIN:
            const userStuff = {
                authed: !!action.user_id,
                user_id: action.user_id,
                payment_method: action.payment_method === true || action.payment_method == '1',
                email: action.email,
                timezone: action.timezone,
                reg_type: action.reg_type,
                reg_type_label: get_reg_type(0, true, {just_map: action.reg_type}),
                has_imports: action.has_imports,
            };

            return {...state, ...userStuff, user: {...state.user, ...userStuff}};

        case USER_LOGIN:
            storage.setItem('st.authed', '1');
            storage.setItem('st.reg_type', action.reg_type);
            storage.setItem('st.user_id', action.user_id);
            storage.setItem('st.email', action.email);
            storage.setItem('st.timezone', action.timezone);
            storage.setItem('st.suspended', action.suspended);
            storage.setItem('st.suspended_reason_id', action.suspended_reason_id);
            storage.setItem('st.payment_method', action.payment_method ? '1' : '0');
            storage.setItem('st.has_imports', action.has_imports ? '1' : '0');

            return {
                ...state,
                authed: true,
                reg_type: action.reg_type,
                reg_type_label: get_reg_type('Not Set', true),
                payment_method: action.payment_method,
                has_imports: action.has_imports,

                user: {
                    ...state.user,
                    authed: true,
                    reg_type: action.reg_type,
                    reg_type_label: get_reg_type('Not Set', true),
                    user_id: action.user_id,
                    email: action.email,
                    suspended: action.suspended,
                    suspended_reason_id: action.suspended_reason_id,
                    has_imports: action.has_imports,
                    timezone: action.timezone,
                },
            };
        case USER_LOGOUT:

            // Persist logout
            storage.clear();
            // storage.removeItem('st.id_token');
            // storage.removeItem('st.expires_in');
            // storage.removeItem('st.token_type');
            // storage.removeItem('st.authed');
            // storage.removeItem('st.reg_type');
            // storage.removeItem('st.user_id');
            // storage.removeItem('st.email');
            // storage.removeItem('st.suspended');
            // storage.removeItem('st.suspended_reason_id');
            // storage.removeItem('st.payment_method');
            // storage.removeItem('st.has_imports');

            if (typeof window !== 'undefined') {
                window.localStorage.removeItem('search_obj');
            }

            // empty out the
            return {
                ...state,
                user_id: 0,
                authed: false,
                reg_type: get_reg_type(),
                reg_type_label: get_reg_type('Not Set', true),
                payment_method: undefined,
                has_imports: false,
                user: {
                    user_id: '',
                    email: '',
                    authed: false,
                    suspended: false,
                    suspended_reason_id: '',
                    reg_type: null,
                    has_imports: false,
                },
                settings: {
                    user_id: null,
                    membership_type: 'Basic Bidder',
                },
            };

        case USER_SETTINGS_PAYMENT_METHOD:
            let other_state_value = {};
            let other_state_settings = {};

            if (action.authnet_cppid) {
                other_state_settings.authnet_cppid = action.authnet_cppid;
            }

            if (typeof action.payment_method != 'undefined') {
                other_state_value.payment_method = action.payment_method;

                storage.setItem('st.payment_method', action.payment_method !== false ? '1' : '0');
            }

            return {...state, settings: {...state.settings, payment_method: action.payment_method, payment_profile: action.payment_profile, ...other_state_settings}, ...other_state_value};

        case USER_SETTINGS_GET:
            return {...state, settings_loading: true, settings_error: null};
        case USER_SETTINGS_GET_SUCCESS:
            const _payment_method = get(action, 'payload.user.payment_method', false);

            if (has(action, 'payload.user.payment_method')) {
                storage.setItem('st.payment_method', action.payload.user.payment_method !== false ? '1' : '0');
            }

            // Update the settings that propagates around the site.
            const new_user = {
                ...state.user,
                email: get(action.payload.user, 'email', state.email),
                timezone: get(action.payload.user, 'timezone', state.timezone),
            };

            return {...state, user: new_user, settings_loading: false, settings_form_changed: false, payment_method: _payment_method, settings: get(action, 'payload.user', {})};
        case USER_SETTINGS_GET_FAIL:
            return {...state, settings_loading: false, settings_error: action.error};
        case USER_SETTINGS_SAVE:
            return {...state, settings_loading: true, settings_error: null};
        case USER_SETTINGS_SAVE_SUCCESS:
            general_success_toastr({message: 'Settings saved', title: 'Success!'});
            return {...state, settings_loading: false, settings_form_changed: false, settings: get(action, 'payload', {})};
        case USER_SETTINGS_SAVE_FAIL:
            return {...state, settings_loading: false, settings_error: action.error};

        case GET_WON_AUCTIONS:
            return {...state, won_auctions_loading: true, won_auctions_error: null};
        case GET_WON_AUCTIONS_SUCCESS:
            return {...state, won_auctions_loading: false, won_auctions_error: null, won_auctions: get(action.payload, 'auctions', []), total_won_auctions: action.payload.total_records};
        case GET_WON_AUCTIONS_FAIL:
            return {...state, won_auctions_loading: false, won_auctions_error: action.error};

        case GET_USER_AUCTION_WATCH:
            return {...state, watchlist_loading: true, error: false};
        case GET_USER_AUCTION_WATCH_FAIL:
            return {...state, watchlist_loading: false, error: action.payload};
        case GET_USER_AUCTION_WATCH_SUCCESS:
            return {...state, watchlist_loading: false, error: false, watchlist: action.payload.watchlist, total_auction_watch: action.payload.total_records};
        case REMOVE_USER_WATCHED_AUTION:
            return {...state, watchlist_loading: true, error: false};
        case REMOVE_USER_WATCHED_AUTION_FAIL:
            return {...state, watchlist_loading: false, error: action.payload};
        case REMOVE_USER_WATCHED_AUTION_SUCCESS:
            general_success_toastr({message: 'Auction removed from AuctionWatch', title: 'Success!'});
            return {...state, watchlist_loading: false, error: false};

        case GET_USER_BOOKMARKED_FACILITIES:
            return {...state, loading_bookmarks: true, error: false};
        case GET_USER_BOOKMARKED_FACILITIES_SUCCESS:
            return {...state, loading_bookmarks: false, error: false, bookmarked_facilities: action.payload.bookmarks, total_bookmarked_facilities: action.payload.total_records};
        case GET_USER_BOOKMARKED_FACILITIES_FAIL:
            return {...state, loading_bookmarks: false, error: action.payload};


        case FORGOT_PASSWORD:
            return {...state, password_loading: true, password_error: false, password_request_done: false};
        case FORGOT_PASSWORD_SUCCESS:
            return {...state, password_loading: false, password_request_done: true};
        case FORGOT_PASSWORD_FAIL:
            return {...state, password_loading: false, password_error: action.payload};

        case SET_FORMATTED_TABLE_DATA:
            return {...state, loading: false, formatted_table_data: action.table_data};
        case NOTIFICATIONS_FORM_CLEAR:
            return {
                ...state,
                notification_zipcode: '',

                notification_radius: '25',
                notification_sunday: '0',
                notification_monday: '0',
                notification_tuesday: '0',
                notification_wednesday: '0',
                notification_thursday: '0',
                notification_friday: '0',
                notification_saturday: '0',
                user_notification: {},
                notification_checked: new Map(),
                user_notification_form_changed: false,
            };
        case NOTIFICATIONS_INPUT_CHANGED:
            return {
                ...state,
                [action.name]: action.value,
                notifications_form: {...state.notifications_form, [action.name]: action.value},
                notification_checked: {...state.notification_checked, [action.name]: (action.value == '1')},
            };
        case DELETE_USER_BOOKMARKED_FACILITY:
            return {...state, loading: true, error: false};
        case DELETE_USER_BOOKMARKED_FACILITY_SUCCESS:
            general_success_toastr({message: 'Bookmarked Facility was removed', title: 'Success!'});
            return {...state, loading: false, error: false, bookmark_deleted: true};
        case DELETE_USER_BOOKMARKED_FACILITY_FAIL:
            return {...state, loading: false, error: 'Error deleting user bookmarked facility', bookmark_deleted: false};
        case UPGRADE_FORM_CLEAR:
            return {...state, upgrade_plan: 'monthly', promo_code: '', upgrade_success: null};
        case UPGRADE_INPUT_FORM_CHANGE:
            return {...state, [action.name]: action.value};
        case CHANGE_MEMBERSHIP:
            return {...state, upgrade_success: {}};
        case CHANGE_MEMBERSHIP_SUCCESS:
            return {...state, membership_type: action.payload.membership_type};
        case CHANGE_MEMBERSHIP_FAIL:
            return {...state, settings_loading: false, upgrade_error: action.payload, upgrade_success: false};

        case USER_BID_BANNER:
            const {type = '', subject = '', body = '', buttonText = false, buttonLink = false} = action.notification;
            return {...state, bid_banner: {type, subject, body, buttonText, buttonLink}};

        case USER_CHECKING_PROMO_CODE:
            return {...state, promo_loading: true, promo_valid: false, promo_error: false};
        case USER_CHECKING_PROMO_CODE_SUCCESS:
            return {...state, promo_loading: false, promo_valid: action.payload.status};
        case USER_CHECKING_PROMO_CODE_FAIL:
            return {...state, promo_loading: false, promo_error: action.error};
        case GET_FM_ACTIVE_AUCTIONS:
            return {...state, fm_active_loading: true, fm_active_error: false};
        case GET_FM_ACTIVE_AUCTIONS_FAIL:
            return {...state, fm_active_loading: false, fm_active_error: action.error, fm_active_auctions: [], fm_active_auctions_count: 0};
        case GET_FM_ACTIVE_AUCTIONS_SUCCESS:
            const {has_review_service: GET_FM_ACTIVE_AUCTIONS_SUCCESS_ars_enabled} = action.payload.user || {};

            return {
                ...state,
                fm_active_loading: false,
                fm_active_error: false,
                fm_active_auctions_count: action.payload.total_records,
                fm_active_auctions: get(action, 'payload.auctions', []),
                ars_enabled: GET_FM_ACTIVE_AUCTIONS_SUCCESS_ars_enabled,
            };
        case ACTIVE_AUCTIONS_INPUT_CHANGED:
            state.date_filter_selected.date = action.value;
            state.date_filter_selected.date_label = action.name;

            return {...state};
        case ACTIVE_SEARCH_INPUT_CHANGED:
            return {...state, fm_active_term: action.value};
        case GET_FM_SOLD_AUCTIONS:
            return {...state, fm_sold_loading: true, fm_sold_error: false};
        case GET_FM_SOLD_AUCTIONS_FAIL:
            return {...state, fm_sold_loading: false, fm_sold_error: action.error, fm_sold_auctions: [], fm_sold_auctions_count: 0};
        case GET_FM_SOLD_AUCTIONS_SUCCESS:
            return {
                ...state,
                fm_sold_loading: false,
                fm_sold_error: false,
                fm_sold_auctions_count: action.payload.total_records,
                fm_sold_auctions: get(action, 'payload.auctions', []),
                ars_enabled: get(action, 'payload.user.has_review_service', false),
            };
        case SOLD_AUCTIONS_INPUT_CHANGED:
            state.date_filter_selected.date = action.value;
            state.date_filter_selected.date_label = action.name;

            return {...state};
        case SOLD_SEARCH_INPUT_CHANGED:
            return {...state, fm_sold_term: action.value};
        case GET_FM_UNSOLD_AUCTIONS:
            return {...state, fm_unsold_loading: true, fm_unsold_error: false};
        case GET_FM_UNSOLD_AUCTIONS_FAIL:
            return {...state, fm_unsold_loading: false, fm_unsold_error: action.error, fm_unsold_auctions: [], fm_unsold_auctions_count: 0};
        case GET_FM_UNSOLD_AUCTIONS_SUCCESS:
            return {
                ...state,
                fm_unsold_loading: false,
                fm_unsold_error: false,
                fm_unsold_auctions_count: action.payload.total_records,
                fm_unsold_auctions: get(action, 'payload.auctions', []),
                ars_enabled: get(action, 'payload.user.has_review_service', false),
            };
        case UNSOLD_AUCTIONS_INPUT_CHANGED:
            state.date_filter_selected.date = action.value;
            state.date_filter_selected.date_label = action.name;

            return {...state};
        case UNSOLD_SEARCH_INPUT_CHANGED:
            return {...state, fm_unsold_term: action.value};
        case GET_FM_CANCELED_AUCTIONS:
            return {...state, fm_canceled_loading: true, fm_canceled_error: false};
        case GET_FM_CANCELED_AUCTIONS_FAIL:
            return {...state, fm_canceled_loading: false, fm_canceled_error: action.error, fm_canceled_auctions: [], fm_canceled_auctions_count: 0};
        case GET_FM_CANCELED_AUCTIONS_SUCCESS:
            return {
                ...state,
                fm_canceled_loading: false,
                fm_canceled_error: false,
                fm_canceled_auctions_count: action.payload.total_records,
                fm_canceled_auctions: get(action, 'payload.auctions', []),
                ars_enabled: get(action, 'payload.user.has_review_service', false),
            };
        case CANCELED_AUCTIONS_INPUT_CHANGED:
            state.date_filter_selected.date = action.value;
            state.date_filter_selected.date_label = action.name;

            return {...state};
        case CANCELED_SEARCH_INPUT_CHANGED:
            return {...state, fm_canceled_term: action.value};
        case GET_FM_CANCELED_AFTER_SOLD_AUCTIONS:
            return {...state, fm_canceled_after_sold_loading: true, fm_canceled_after_sold_error: false};
        case GET_FM_CANCELED_AFTER_SOLD_AUCTIONS_FAIL:
            return {...state, fm_canceled_after_sold_loading: false, fm_canceled_after_sold_error: action.error, fm_canceled_after_sold_auctions: [], fm_canceled_after_sold_auctions_count: 0};
        case GET_FM_CANCELED_AFTER_SOLD_AUCTIONS_SUCCESS:
            return {
                ...state,
                fm_canceled_after_sold_loading: false,
                fm_canceled_after_sold_error: false,
                fm_canceled_after_sold_auctions_count: action.payload.total_records,
                fm_canceled_after_sold_auctions: get(action, 'payload.auctions', []),
                ars_enabled: get(action, 'payload.user.has_review_service', false),
            };
        case CANCELED_AFTER_SOLD_AUCTIONS_INPUT_CHANGED:
            state.date_filter_selected.date = action.value;
            state.date_filter_selected.date_label = action.name;

            return {...state};
        case CANCELED_AFTER_SOLD_SEARCH_INPUT_CHANGED:
            return {...state, fm_canceled_after_sold_term: action.value};
        case GET_FM_DRAFT_AUCTIONS:
            return {...state, fm_draft_loading: true, fm_draft_error: false};
        case GET_FM_DRAFT_AUCTIONS_FAIL:
            return {...state, fm_draft_loading: false, fm_draft_error: action.error, fm_draft_auctions: [], fm_draft_auctions_count: 0};
        case GET_FM_DRAFT_AUCTIONS_SUCCESS:
            return {
                ...state,
                fm_draft_loading: false,
                fm_draft_error: false,
                fm_draft_auctions_count: action.payload.total_records,
                fm_draft_auctions: action.payload.auction_drafts,
                ars_enabled: get(action, 'payload.user.has_review_service', false),
            };
        case DRAFT_AUCTIONS_INPUT_CHANGED:
            state.date_filter_selected.date = action.value;
            state.date_filter_selected.date_label = action.name;

            return {...state};
        case DRAFT_SEARCH_INPUT_CHANGED:
            return {...state, fm_draft_term: action.value};
        case GET_FM_IMPORTED_AUCTIONS:
            return {...state, fm_imported_loading: true, fm_imported_error: false};
        case GET_FM_IMPORTED_AUCTIONS_FAIL:
            return {...state, fm_imported_loading: false, fm_imported_error: action.error, fm_imported_auctions: [], fm_imported_auctions_count: 0};
        case GET_FM_IMPORTED_AUCTIONS_SUCCESS:
            return {...state, fm_imported_loading: false, fm_imported_error: false, fm_imported_auctions_count: action.payload.total_records, fm_imported_auctions: get(action, 'payload.auction_imports', [])};
        case IMPORTED_SEARCH_INPUT_CHANGED:
            return {...state, fm_imported_term: action.value};
        case GET_FM_FACILITIES:
            return {...state, fm_facilities_error: false, fm_facilities_loading: true};
        case GET_FM_FACILITIES_SUCCESS:
            return {...state, fm_facilities_error: false, fm_facilities_loading: false, fm_facilities: get(action, 'payload.facilities', []), fm_facilities_count: action.payload.total_records};
        case GET_FM_FACILITIES_FAIL:
            return {...state, fm_facilities_loading: false, fm_facilities_error: action.error, fm_facilities: [], fm_facilities_count: 0};
        case FACILITIES_SEARCH_INPUT_CHANGED:
            return {...state, fm_facilities_term: action.value};
        case CUSTOM_DATE_INPUT_CHANGED:
            return {...state, [`${action.name}`]: action.value};
        case GET_FM_LIVE_AUCTIONS:
            return {...state, fm_live_loading: true, fm_live_error: false};
        case GET_FM_LIVE_AUCTIONS_FAIL:
            return {...state, fm_live_loading: false, fm_live_error: action.error, fm_live_auctions: [], fm_live_auctions_count: 0};
        case GET_FM_LIVE_AUCTIONS_SUCCESS:
            return {...state, fm_live_loading: false, fm_live_error: false, fm_live_auctions_count: action.payload.total_records, fm_live_auctions: get(action, 'payload.live_auctions', [])};
        case LIVE_AUCTIONS_INPUT_CHANGED:
            state.date_filter_selected.date = action.value;
            state.date_filter_selected.date_label = action.name;

            return {...state};
        case LIVE_SEARCH_INPUT_CHANGED:
            return {...state, fm_live_term: action.value};
        default:
            return state;
    }
};