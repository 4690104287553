import React, { useEffect } from 'react';
import { useService } from '../../hooks/useService';
import { connect } from 'react-redux';
import AuctionTile from './AuctionTile';
import 'styles/components/auction-tile.less';

const NearbyAuctions = (props) => {
    const [{ data, loading }, fireLoadNearbyAuctions] = useService({
        url: `/auctions/nearby?search_type=zipcode&search_term=${props.zipcode}&search_radius=25&exclude_facility_id=${props.auction.facility_id}&limit=3`,
    });

    useEffect( () => {
        fireLoadNearbyAuctions();
    }, [props.auction.auction_id]);

    return (loading || props.navigatingToAuction)
        ? null
        : data?.auctions?.length > 0
            ? (
                <div className={`other-auctions-container other-auctions-nearby ${!props.show && 'hidden'}`}>
                    <div className="container">
                        <h2 className="section-title">Other Auctions Nearby</h2>

                        <div className="auctions-container">
                            <div className="auction-tiles-special-center">
                                {data?.auctions?.filter((auction) => auction.auction_id != props.auction.auction_id).map((auction, i) => {
                                    if (i > 2) {
                                        return null;
                                    }

                                    return (
                                        <AuctionTile key={auction.auction_id} auction={auction} otherAuctions />
                                    );
                                })}
                            </div>
                        </div>
                        <div className="clearfix" />
                    </div>
                </div>
            ) : null;
};

const mapStateToProps = ({ auction }) => ({
    auction: auction.auction,
    zipcode: auction?.auction?.facility?.zipcode,
    navigatingToAuction: auction.navigatingToAuction,
});

export default connect(mapStateToProps)(NearbyAuctions);