import Modal from 'react-bootstrap/Modal';
import Button from 'components/partials/Fields/Button';
import { Input } from 'components/partials/Fields/FormikInput';
import { Form } from 'components/FormikForm';
import { useFormikContext } from 'formik';
import yup from 'helpers/validation';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';

const SubmitButton = props => {
    const { values, isValid } = useFormikContext();

    return (
        <Button
            label="Send Invite"
            variant="primary"
            disabled={!values.authorized_email || !isValid}
            {...props}
        />
    );
};

const AddPickupPerson = ({
    setEmail,
    handleConfirm,
    show,
    setShow,
}) => {

    const formSchema = yup.object().shape({
        authorized_email: yup.string().email('Invalid email').required('This field is required.'),
    });

    return (
        <Modal
            name="add-pickup-person-modal"
            show={show}
            className="add-pickup-person-modal"
            onHide={() => setShow(false)}
        >
            <ModalHeader closeButton>
                <ModalTitle>Add Authorized Pickup Person</ModalTitle>
            </ModalHeader>
            <Form
                initialValues={{authorized_email: ''}}
                onSubmit={handleConfirm}
                validationSchema={formSchema}
            >
                <ModalBody>
                    <Input
                        formik
                        label="Email"
                        name="authorized_email"
                        onChange={(e) => {
                            setEmail(e.value);
                        }}
                    />
                </ModalBody>
                <ModalFooter>
                    <SubmitButton onClick={handleConfirm} />
                </ModalFooter>
            </Form>
        </Modal>
    );
};

export default AddPickupPerson;
