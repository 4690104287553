import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { APP_CONFIG } from 'Config';
import LayoutHeader from 'layouts/LayoutHeader';
import Sidebar from 'layouts/Sidebar';
import Footer from 'layouts/LayoutFooter';
import PageTitle from 'components/partials/PageTitle';
import OtherAuctions from 'components/Auctions/OtherAuctions';
import NearbyAuctions from 'components/Auctions/NearbyAuctions';
import Thingamabob from 'components/Dev/Thingamabob';
import { screenResize, setPageContainerClass } from 'actions';
import UserStatusNotification from 'components/UserStatusNotification';
import SwitchClassic from 'components/SwitchClassic';
import LivePerson from 'layouts/LivePerson';
import SMSOptInAlert from '../components/SMSOptInAlert';
import { useBidNotification } from 'context/BidNotificationContext';
import 'styles/Main.less';
import 'styles/common.less';
import 'styles/vendors/toastr.less';
import './styles.scss';

const MainLayout = (props) => {
    const {
        auction_id,
        showOtherAuctions = false,
        setPageContainerClass,
        screenResize,
        containerClass,
        children,
        showTopMessageBar,
    } = props;

    const [state, setState] = useState({
        notification: false,
        element_pos_fixed: false,
        affix: false,
        affixOffset: 133,
    });

    const { bidBanner } = useBidNotification();

    useEffect(() => {
        // Initialize the container class
        eventWindowResize();

        // Setup window listener to automatically update the container class
        window.addEventListener('resize', eventWindowResize);
        window.addEventListener('scroll', eventWindowScroll);

        return () => {
            // Remove window listener that automatically update the container class
            window.removeEventListener('resize', eventWindowResize);
            window.removeEventListener('scroll', eventWindowScroll);
        };
    }, []);

    const eventWindowResize = () => {
        if (typeof window === 'undefined') {
            return false;
        }
        setPageContainerClass({containerClass: `container${window.innerWidth <= 1200 ? '-fluid' : ''}`});
        screenResize({width: window.innerWidth});
    };

    const eventWindowScroll = () => setState(prevState => ({
        ...prevState,
        element_pos_fixed: window.scrollY < 260,
        affix: window.scrollY > state.affixOffset,
    }));

    const renderBidBanner = () => {
        if (
            (typeof window === 'undefined' ||
            children?.props?.location?.pathname !== '/auctions/[state]/[city]/[auction_id]') &&
            !bidBanner.body && !bidBanner.subject
        ) {
            return null;
        }

        const { type, subject, body } = bidBanner;
        if (!body && !subject) return null;

        //removed 'affix'
        const affixClass = state.affix ? ' affix-top' : '';

        return (
            <div className={`top-notification notification-${type} stick-top ${affixClass}`}>
                <span className="subject">{subject}</span>
                <span className="body">{body}</span>
            </div>
        );
    };

    const { element_pos_fixed } = state;

    const thingamabob = get(APP_CONFIG, 'DEV', false) ? <Thingamabob/> : null;
    const subComponent = auction_id ? <OtherAuctions show={showOtherAuctions}/> : null;
    const nearbyAuctions = auction_id ? <NearbyAuctions show={showOtherAuctions} /> : null;
    const maintenanceContainerClass = APP_CONFIG.REACT_APP_MAINTENANCE_NOTICE && 'layout__container--maintenance';

    return (
        <div>
            <div className="content-container" id="content-container">
                <Sidebar showTopMessageBar={showTopMessageBar} />
                {showTopMessageBar && (
                    <div className={`top-switch-bar-top is-${element_pos_fixed ? 'fixed' : 'relative'}`}>
                        <SwitchClassic />
                    </div>
                )}
                <LayoutHeader />
                <div className={`pageContent ${maintenanceContainerClass}`}>
                    <PageTitle />
                    {renderBidBanner()}
                    <div className="clearfix" />
                    <div className={containerClass}>
                        <SMSOptInAlert />
                        <UserStatusNotification />
                        {children}
                    </div>
                </div>
                {thingamabob}
                {subComponent}
                {nearbyAuctions}
                <Footer />
            </div>
            <LivePerson />
        </div>
    );
};

const mapStateToProps = ({ page, user }) => {
    const { user: current_user } = user;

    return {
        containerClass: page.data.containerClass,
        user: current_user,
    };
};

export default connect(mapStateToProps, { setPageContainerClass, screenResize })(MainLayout);