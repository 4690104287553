// general auction types
export const GET_ONLINE_AUCTIONS = 'get_online_auctions';
export const GET_ONLINE_AUCTIONS_SUCCESS = 'get_online_auctions_success';
export const GET_ONLINE_AUCTIONS_FAIL = 'get_online_auctions_fail';

export const ONLINE_FILTER_SEARCH = 'online_filter_search';
export const ONLINE_FILTER_SEARCH_SUCCESS = 'online_filter_search_success';
export const ONLINE_FILTER_SEARCH_FAIL = 'online_filter_search_fail';

export const GET_CATEGORIES = 'get_categories';
export const GET_CATEGORIES_SUCCESS = 'get_categories_success';
export const GET_CATEGORIES_FAIL = 'get_categories_fail';

export const GET_AUCTION_SEARCH_RADIUS = 'get_auction_search_radius';
export const GET_AUCTION_SEARCH_RADIUS_SUCCESS = 'get_auction_search_radius_success';
export const GET_AUCTION_SEARCH_RADIUS_FAIL = 'get_auction_search_radius_fail';

export const GET_AUCTION = 'get_auction';
export const GET_AUCTION_SUCCESS = 'get_auction_success';
export const GET_AUCTION_FAIL = 'get_auction_fail';

export const CLEAR_AUCTION_DATA = 'clear_auction_data';
export const CLEAR_HOME_AUCTION_DATA = 'clear_home_auction_data';

export const GET_TOP_AUCTIONS = 'get_top_auctions';
export const GET_TOP_AUCTIONS_SUCCESS = 'get_top_auctions_success';
export const GET_TOP_AUCTIONS_FAIL = 'get_top_auctions_fail';
export const UPDATE_TOP_AUCTIONS = 'update_top_auctions';

export const GET_AUCTIONS_ENDING_SOON = 'get_auctions_ending_soon';
export const GET_AUCTIONS_ENDING_SOON_FAIL = 'get_auctions_ending_soon_fail';
export const GET_AUCTIONS_ENDING_SOON_SUCCESS = 'get_auctions_ending_soon_success';
export const UPDATE_AUCTIONS_ENDING_SOON = 'update_auctions_ending_soon';

export const SET_FILTER_CATEGORY = 'set_filter_category';
export const CLEAR_AUCTION_FILTERS = 'clear_auction_fiters';

export const GET_COMING_SOON_AUCTIONS = 'get_coming_soon_auctions';
export const GET_COMING_SOON_AUCTIONS_SUCCESS = 'get_coming_soon_auctions_success';
export const GET_COMING_SOON_AUCTIONS_FAIL = 'get_coming_soon_auctions_fail';

export const AUCTION_POSTING_INPUT='auction_posting_input';
export const AUCTION_POSTING_FORM_CLEAR = 'auction_posting_input_clear';

export const AUCTION_SAVE_DETAILS = 'auction_save_details';
export const AUCTION_SAVE_DETAILS_SUCCESS = 'auction_save_details_success';
export const AUCTION_SAVE_DETAILS_FAIL = 'auction_save_details_fail';

export const AUCTION_CANCEL_FORM = 'auction_cancel_form';
export const AUCTION_CANCEL = 'auction_cancel';
export const AUCTION_CANCEL_SUCCESS = 'auction_cancel_success';
export const AUCTION_CANCEL_FAIL = 'auction_cancel_fail';

export const AUCTION_IMAGE_GET = 'auction_image_get';
export const AUCTION_IMAGE_GET_SUCCESS = 'auction_image_get_success';
export const AUCTION_IMAGE_GET_FAIL = 'auction_image_get_fail';

export const AUCTION_IMAGE_EDIT = 'auction_image_edit';
export const AUCTION_IMAGE_EDIT_SUCCESS = 'auction_image_edit_success';
export const AUCTION_IMAGE_EDIT_FAIL = 'auction_image_edit_fail';

export const SAVE_AUCTION_AS_DRAFT = 'save_auction_as_draft';
export const SAVE_AUCTION_AS_DRAFT_SUCCESS = 'save_auction_as_draft_success';
export const SAVE_AUCTION_AS_DRAFT_FAIL = 'save_auction_as_draft_fail';

export const POST_AUCTION = 'post_auction';
export const POST_AUCTION_SUCCESS = 'post_auction_success';
export const POST_AUCTION_FAIL = 'post_auction_fail';

export const AUCTION_STATUS_UNSOLD = '3';

export const EXPIRES_IN = 'EXPIRES_IN';