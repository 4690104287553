import React, { forwardRef, ChangeEvent } from 'react';
import { has } from 'lodash';
import InputMask from 'react-input-mask';
import HelpBlock from '../HelpBlock';
import './styles.scss';

interface BaseProps {
    children: React.ReactNode
}

interface BaseInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    mask?: string
    error?: string
}

interface BaseAddonProps extends React.HTMLAttributes<HTMLDivElement> {
    textProps?: React.HTMLAttributes<HTMLSpanElement>
    children: React.ReactNode
}

const Base: React.FC<BaseProps> & {
    Input: React.ForwardRefExoticComponent<BaseInputProps & React.RefAttributes<HTMLInputElement>>
    Addon: React.FC<BaseAddonProps>
} = ({ children }) => <div className="base-input-group">{children}</div>;

Base.Input = forwardRef<HTMLInputElement, BaseInputProps>((props, ref) => {
    const { mask, ...inputProps } = props;

    if (mask) {
        return (
            <InputMask mask={mask} {...inputProps}>
                {(maskInputProps: React.InputHTMLAttributes<HTMLInputElement>) => 
                    <input {...maskInputProps} disabled={props.disabled} ref={ref} />
                }
            </InputMask>
        );
    }
    return <input {...inputProps} ref={ref} />;
});

Base.Addon = ({ children, textProps, ...props }: BaseAddonProps) => (
    <div {...props}>
        <span {...textProps}>
            {children}
        </span>
    </div>
);

interface InputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
    type?: string
    name: string
    className?: string
    outerClassReplace?: string
    outerClassName?: string
    help?: boolean | string
    attributes?: Record<string, any>
    label?: string
    labelAttrs?: React.LabelHTMLAttributes<HTMLLabelElement>
    withIcon?: boolean | string
    wrap?: boolean
    required?: boolean
    error?: string
    value?: string | number
    onLblDoubleClick?: () => void
    feedbackClassName?: string
    onIconClick?: (e: React.MouseEvent<HTMLElement>) => void
    prependComponent?: React.ReactNode
    step?: string
    rightAddon?: boolean
    size?: string | number
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void
    showHide?: () => void
    show?: boolean
    protocol?: string
}

const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
    const {
        type = 'text',
        name,
        className = 'form-control',
        outerClassReplace = 'form-group ',
        outerClassName = '',
        help = false,
        attributes: _attributes = {},
        label = '',
        labelAttrs = {},
        withIcon = false,
        wrap = true,
        required = false,
        error,
        value,
        onLblDoubleClick,
        onIconClick,
        step = '1',
        rightAddon = true,
        size,
        onChange,
        showHide,
        show,
        ...others
    } = props;

    const { id = name } = others;

    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
            onChange(e);
        }
    };

    const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
        if (onChange) {
            onChange(e as any);
        }
    };

    const attributes: Record<string, any> = {
        type,
        className,
        id,
        name,
        required,
        withIcon,
        value,
        error,
        step,
        size,
        ...others,
        ..._attributes,
    };

    attributes['onChange'] = handleOnChange;

    if (has(attributes, 'value.amount')) {
        attributes.value = attributes.value.amount;
    }

    const inputAttributes: Record<string, any> = { ...attributes };

    inputAttributes['ref'] = ref;

    if (type === 'email') {
        inputAttributes.type = 'email';
    }

    inputAttributes.className = `${className} ${size ? 'form-control-lg' : ''}`;

    const addonClass = 'base-input-group-append';

    const typeMap: Record<string, () => React.JSX.Element> = {
        money: () => (
            <Base>
                <Base.Addon className={addonClass}>$</Base.Addon>
                <Base.Input min={0} {...inputAttributes} error={error} />
                {rightAddon && <Base.Addon className={addonClass}>.00</Base.Addon>}
            </Base>
        ),
        number: () => (
            <Base>
                <Base.Addon className={addonClass}>$</Base.Addon>
                <Base.Input min={0} {...inputAttributes} error={error} />
                {rightAddon && <Base.Addon className={addonClass}>.00</Base.Addon>}
            </Base>
        ),
        percent: () => (
            <Base>
                <Base.Input min="0" max="100" step={step} {...inputAttributes} error={error} />
                <Base.Addon className={addonClass}>%</Base.Addon>
            </Base>
        ),
        date: () => {
            inputAttributes.placeholder = 'MM/DD/YYYY';
            return (
                <Base>
                    <Base.Input {...inputAttributes} error={error} />
                    {rightAddon &&
                        <Base.Addon className={addonClass}>
                            <i className="far fa-calendar-alt" />
                        </Base.Addon>}
                </Base>
            );
        },
        time: () => {
            inputAttributes.placeholder = 'HH:MM AM/PM';
            return (
                <Base>
                    <Base.Input {...inputAttributes} error={error} />
                    {rightAddon && <Base.Addon className={addonClass}>
                        <i className="fa fa-clock-o" />
                    </Base.Addon>}
                </Base>
            );
        },
        auctionDate: () => {
            inputAttributes.placeholder = 'MM/DD/YYYY';
            return (
                <Base>
                    <Base.Input {...inputAttributes} error={error} />
                    {rightAddon && <Base.Addon className={addonClass}>
                        <i className="far fa-calendar-alt" />
                    </Base.Addon>}
                </Base>
            );
        },
        auctionTime: () => {
            inputAttributes.placeholder = 'HH:MM AM/PM';
            return (
                <Base>
                    <Base.Input {...inputAttributes} error={error} />
                    {rightAddon && <Base.Addon className={addonClass}>
                        <i className="fa fa-clock-o" />
                    </Base.Addon>}
                </Base>
            );
        },
        search: () => (
            <Base>
                <Base.Input {...inputAttributes} error={error} />
                {rightAddon &&
                    <Base.Addon textProps={{className: 'p-0 border-0'}}>
                        <button
                            type="submit"
                            className="btn btn-regular btn-blue btn-search"
                            onClick={onIconClick}
                        >
                            <i className="fa fa-search fa-lg" />
                        </button>
                    </Base.Addon>}
            </Base>
        ),
        protocol: () => (
            <Base>
                <Base.Addon textProps={{ className: 'p-0 border-0' }} className="addon-select">
                    <select
                        name="protocol"
                        className="form-control"
                        onChange={handleSelectChange}
                        value={props.protocol || 'http://'}
                    >
                        <option value="http://">http://</option>
                        <option value="https://">https://</option>
                    </select>
                </Base.Addon>
                <Base.Input {...inputAttributes} error={error} />
            </Base>
        ),
        eye: () => (
            <Base>
                <Base.Input {...inputAttributes} error={error} />
                {rightAddon &&
                    <Base.Addon className={addonClass}>
                        <i
                            onClick={showHide}
                            className={show ? 'fas fa-eye-slash' : 'fas fa-eye'}
                        />
                    </Base.Addon>}
            </Base>
        ),
        default: () => <Base.Input {...inputAttributes} error={error} />,
    };

    return !wrap ? (
        <Base.Input {...inputAttributes} error={error} />
    ) : (
        <div className={`base-input-div ${outerClassReplace} ${outerClassName} ${error && 'parsley-has-error'}`}>
            {label && (
                <label
                    htmlFor={attributes.id}
                    className="control-label"
                    {...labelAttrs}
                    onDoubleClick={onLblDoubleClick}
                >
                    {label}
                </label>
            )}
            {typeMap[withIcon as string || 'default']()}
            <HelpBlock help={help} />
            {error && <p className='parsley-errors-list filled'>{error}</p>}
        </div>
    );
});

export default Input;