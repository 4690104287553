import React from 'react';
import Alert from 'components/partials/Alert';
import { usePage } from 'context/PageContext';

import 'styles/components/PageTitle.less';

const PageTitle = () => {
    const { pageData } = usePage();
    const { title, h1, containerClass, marginBottom } = pageData.data;

    if (!title && !h1) {
        // Create space on the top of the page when no page heading
        return (
            <div className="marginB20">
                <Alert />
                <div className="clearfix" />
            </div>
        );
    }

    return (
        <div className={`d-flex align-items-center ${marginBottom}`}>
            <Alert />
            <div className={containerClass}>
                <h1 className="m-0">
                    <strong>
                        {h1 || title}
                    </strong>
                </h1>
            </div>
            <div className="clearfix" />
        </div>
    );
};

export default PageTitle;