import moment from 'moment';
import { useMemo, useRef } from 'react';
import { useTimerContext } from 'context/TickContext';
import { pad } from 'helpers/number';

const useTimer = ({
    from,
    current,
} = {}) => {
    const { ticker } = useTimerContext();
    const lastValidFrom = useRef(null);

    const getNumberOfDigits = (number) => {
        return Math.abs(number).toString().length;
    };

    const initialTimeOffset = useMemo(() => {
        const initialCurrent = moment(current);
        const now = moment();
        return now.diff(initialCurrent);
    }, [current]);

    const time = useMemo(() => {
        const currentTime = moment().subtract(initialTimeOffset);

        if (!from) {
            const duration = moment.duration(lastValidFrom.current - currentTime);
            const daysDigits = getNumberOfDigits(Math.trunc(duration.asDays()));
            const padSize = daysDigits > 2 ? daysDigits : 2;

            return {
                years: pad(duration.years(), 2),
                days: pad(Math.trunc(duration.asDays()), padSize),
                hours: pad(duration.hours(), 2),
                minutes: pad(duration.minutes(), 2),
                seconds: pad(duration.seconds(), 2),
            };
        }

        const duration = moment.duration(from - currentTime);
        const daysDigits = getNumberOfDigits(Math.trunc(duration.asDays()));
        const padSize = daysDigits > 2 ? daysDigits : 2;
        lastValidFrom.current = from;

        return {
            years: pad(duration.years(), 2),
            days: pad(Math.trunc(duration.asDays()), padSize),
            hours: pad(duration.hours(), 2),
            minutes: pad(duration.minutes(), 2),
            seconds: pad(duration.seconds(), 2),
        };
    }, [ticker]);

    const expired = useMemo(() => {
        const rtExpired = {
            years: time.years == '00',
            days: false,
            hours: false,
            minutes: false,
            seconds: false,
            expired: false,
        };

        if (rtExpired.years && time.days == '00') {
            rtExpired['days'] = true;

            if (time.hours == '00') {
                rtExpired['hours'] = true;

                if (time.minutes == '00') {
                    rtExpired['minutes'] = true;

                    if (time.seconds == '00') {
                        rtExpired['seconds'] = true;
                        rtExpired['expired'] = true;
                    }
                }
            }
        }

        if (Object.values(time).some(value => parseInt(value) < 0)) {
            rtExpired.years = true;
            rtExpired.days = true;
            rtExpired.hours = true;
            rtExpired.minutes = true;
            rtExpired.seconds = true;
            rtExpired.expired = true;
        }

        return rtExpired;
    }, [time]);

    return [{ time, expired }, {}];
};

export default useTimer;