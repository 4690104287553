import { createContext, useContext } from 'react';
import usePolling from 'hooks/usePolling';

export const TimerContext = createContext({});
export const useTimerContext = () => useContext(TimerContext);

const TimerProvider = ({ children, pollingProps = {}, currentDate = 1 }) => {
    const [{ ticker, isPolling }, { startPolling, stopPolling }] = usePolling({
        timeoutMs: 1000,
        startAt: currentDate,
        ...pollingProps,
    });

    return (
        <TimerContext.Provider
            value={{
                ticker,
                isPolling,
                startTimer: startPolling,
                stopTimer: stopPolling,
            }}
        >
            {children}
        </TimerContext.Provider>
    );
};

export default TimerProvider;