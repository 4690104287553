import * as ReactBootstrap from 'react-bootstrap';
import { Loading } from 'components/Loading';
import { Formik, FormikConfig, FormikProps, FormikValues, useFormikContext } from 'formik';
import React, { useEffect } from 'react';

interface BootstrapWrapProps {
    handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
    children: React.ReactNode;
    className?: string;
    values: FormikValues;
    errors: FormikValues;
}

const BootstrapWrap = ({
    handleSubmit,
    className,
    children,
}: BootstrapWrapProps): React.JSX.Element => {
    return (
        <ReactBootstrap.Form noValidate className={className} onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            e.stopPropagation();
            handleSubmit(e);
        }}>
            {children}
        </ReactBootstrap.Form>
    );
};

interface FormProps extends Omit<FormikConfig<FormikValues>, 'children'> {
    loading?: boolean;
    children: React.ReactNode | ((props: FormikProps<FormikValues>) => React.ReactNode);
    defaultData?: FormikValues;
    className?: string;
}

export function ScrollToError() {
    const { submitCount, errors } = useFormikContext();

    useEffect(() => {
        if (submitCount > 0 && Object.keys(errors).length > 0) {
            const firstError = document.querySelector('.parsley-errors-list.filled, .invalid-feedback');
            if (firstError) {
                firstError.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }
    }, [submitCount]);

    return null;
}

export const Form = ({
    loading,
    children,
    defaultData = {},
    initialValues,
    onSubmit,
    className,
    ...props
}: FormProps): React.JSX.Element => {
    if (loading) {
        return <Loading/>;
    }

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues || defaultData}
            onSubmit={onSubmit}
            {...props}
        >
            {(formikProps: FormikProps<FormikValues>) => {
                const { handleSubmit, values, errors, ...rest } = formikProps;
                return (
                    <BootstrapWrap
                        handleSubmit={handleSubmit}
                        values={values}
                        errors={errors}
                        className={className}
                        {...rest}
                    >
                        <>
                            <ScrollToError/>
                            {typeof children === 'function'
                                ? children(formikProps)
                                : children}
                        </>
                    </BootstrapWrap>
                );
            }}
        </Formik>
    );
};
