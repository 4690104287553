import { Auth } from 'aws-amplify';

const LoginService = {
    async login({ email, password }) {
        return await Auth.signIn(email.trim().toLowerCase(), password.trim());
    },

    async logout() {
        return await Auth.signOut({ global: true });
    },
};
export default LoginService;
