import { useState, useEffect, useMemo } from 'react';

interface UsePollingProps {
    timeoutMs?: number
    startAt?: number
    continuePolling?: () => Promise<boolean>

}

const usePolling = ({
    timeoutMs = 5000,
    startAt = 0,
    continuePolling = async () => true,
} = {}) => {
    const [ticker, setTicker] = useState(null);
    const [onContinuePolling, setOnContinuePolling] = useState(null);

    const isPolling = useMemo(() => {
        return Number.isInteger(ticker);
    }, [ticker]);

    useEffect(() => {
        return () => {
            setTicker(null);
        };
    }, []);

    useEffect(() => {
        (async () => {
            if (Number.isInteger(ticker)) {
                const response = await onContinuePolling();

                await new Promise(res => {
                    setTimeout(res, timeoutMs);
                });

                // trigger another tick
                if (!response) {
                    setTicker(null);
                } else {
                    if (Number.isInteger(ticker)) {
                        setTicker(ticker + timeoutMs);
                    }
                }
            }
        })();
    }, [ticker]);

    const startPolling = async ({ continuePolling: _continuePolling = continuePolling } = {}) => {
        if (!isPolling) {
            setOnContinuePolling(() => _continuePolling);
            setTicker(startAt);
        }
    };

    const stopPolling = () => {
        setTicker(null);
    };

    return [{ ticker, isPolling }, { startPolling, stopPolling }];
};

export default usePolling;