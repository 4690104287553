import { useEffect, useState } from 'react';
import AuthorizedPickupPerson from 'pages/account/settings/authorized-pickup-person';
import { useService } from 'hooks/useService';
import { useGlobalContext } from 'context/GlobalContext';
import { useLoginContext } from 'components/Login/_context/useLoginContext';

export const Afp = () => {
    const afpLimit = {
        'Basic Bidder': 0,
        'ProBidder': 1,
        'ProBidder Max': 3,
        'ProBusiness': 5,
    };

    const { user } = useGlobalContext();
    const { runChecksAndLogin } = useLoginContext();
    const [loading, setLoading] = useState(false);
    const [_, fireGetAuthorizedPickupPersons] = useService({
        url: 'users/account/authorized-invites',
    });

    const removePickupPersonCallback = async () => {
        setLoading(true);
        const { num_results } = await fireGetAuthorizedPickupPersons();

        if (!num_results || Number(num_results) <= afpLimit[user?.membership?.type]) {
            localStorage.setItem('require_afp', 'false');
            return runChecksAndLogin();
        }

        setLoading(false);
    };

    useEffect(() => {
        removePickupPersonCallback();
    }, []);

    return (
        <>
            <div className="alert alert-warning">
                Based on the current membership {user?.membership?.type}, the number of Authorized Pickup Person you can
                have is {afpLimit[user?.membership?.type]}. Please review and adjust the list.
            </div>
            <AuthorizedPickupPerson
                removePickupPersonCallback={removePickupPersonCallback}
                loginUser={user.user_id}
                add={false}
                canRespond={false}
                loading={loading}
            />
        </>
    );
};
