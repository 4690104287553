import React, { createContext, ReactNode, useCallback, useContext, useState } from 'react';
import Cookies from 'js-cookie';
import { useService } from 'hooks/useService';
import storage from 'helpers/storage';
import { useRouter } from 'next/router';
import { useGlobalContext } from 'context/GlobalContext';
import { general_error_toastr } from 'components/partials/Notification';
import LoginService from 'services/LoginService';
import ReactGA from 'react-ga4';
import { get_utc_datetime } from 'helpers/date';
import { AUCTIONEER } from 'helpers/users';

const NOT_AUTHORIZED_EXCEPTION = 'NotAuthorizedException';
const USER_NOT_FOUND_EXCEPTION = 'UserNotFoundException';

interface LoginContextProps {
    handleSuccessfulLogin: ({ otp_expires }) => unknown;
    runChecksAndLogin: ({ otp_expires }) => void;
    requirePrivacy: boolean;
    requireTerms: boolean;
    requirePhone: boolean;
    requireMfa: boolean;
    requireAfp: boolean;
    phone: string;
    setRequirePhone: (value: boolean) => void;
    checkEmailVerified: ({ value }) => void;
    handleLoginError: (err: string) => void;
    isEmailVerified: boolean;
    isVerificationPending: boolean;
    isVerificationRejected: boolean;
    isAccountIncomplete: boolean;
    isValidCredentials: boolean;
    setIsValidCredentials: (value: boolean) => void;
}

export const LoginContext = createContext<LoginContextProps>({} as LoginContextProps);

export const useLoginContext = () => useContext(LoginContext);

interface LoginProviderProps {
    children: ReactNode;
}

interface CheckEmailVerifiedResponse {
    exists: boolean;
    verified: boolean;
    pending: boolean;
    rejected: boolean;
}

export const LoginProvider: React.FC<LoginProviderProps> = ({ children }) => {
    const router = useRouter();
    const { getUser } = useGlobalContext();
    const [_, fireCheckEmailVerified] = useService<CheckEmailVerifiedResponse>({ url: 'users/check/email-verified' });
    const [isEmailVerified, setIsEmailVerified] = useState<boolean>(true);
    const [isAccountIncomplete, setIsAccountIncomplete] = useState<boolean>(false);
    const [isVerificationPending, setIsVerificationPending] = useState<boolean>(false);
    const [isVerificationRejected, setIsVerificationRejected] = useState<boolean>(false);
    const [isValidCredentials, setIsValidCredentials] = useState<boolean>(true);
    const [requirePrivacy, setRequirePrivacy] = useState<boolean>(false);
    const [requireTerms, setRequireTerms] = useState<boolean>(false);
    const [requirePhone, setRequirePhone] = useState<boolean>(false);
    const [requireMfa, setRequireMfa] = useState<boolean>(false);
    const [requireAfp, setRequireAfp] = useState<boolean>(false);
    const [phone, setPhone] = useState<string>('');

    const checkPrivacy = (_user) => {
        if (_user.require_privacy) {
            setRequirePrivacy(true);
            return true;
        }

        return false;
    };

    const checkTerms = (_user) => {
        scrollTo(0, 0);

        if (_user.require_terms) {
            setRequirePrivacy(false);
            setRequireTerms(true);
            return true;
        }
        return false;
    };

    const checkMfa = (_user) => {
        if (_user.require_phone || _user.require_mfa) {
            setRequirePrivacy(false);
            setRequireTerms(false);
            setRequirePhone(_user.require_phone);
            setRequireMfa(_user.require_mfa);
            setPhone(_user.phone);
            return true;
        }
        return false;
    };

    const checkAfp = (_user) => {
        if (_user.require_afp) {
            setRequireAfp(true);
            return true;
        }
        return false;
    };  

    const runChecksAndLogin = async ({ otp_expires } = { otp_expires: null }) => {
        const _user = await getUser({
            action: 'logged-in',
        });

        if (checkPrivacy(_user)) return;
        if (checkTerms(_user)) return;
        if (checkMfa(_user)) return;
        if (checkAfp(_user)) return;

        return handleSuccessfulLogin({ otp_expires });
    };

    const setLoginStorageItems = user => {
        storage.setItem('st.authed', '1');
        storage.setItem('st.reg_type', user.reg_type);
        storage.setItem('st.user_id', user.user_id);
        storage.setItem('st.email', user.email);
        storage.setItem('st.timezone', user.timezone);
        storage.setItem('st.suspended', user.suspended);
        storage.setItem('st.suspended_reason_id', user.suspended_reason_id);
        storage.setItem('st.payment_method', user.payment_method ? '1' : '0');
        storage.setItem('st.has_imports', user.has_imports ? '1' : '0');
    };

    const handleNoRegType = () => {
        storage.setItem('st.authed', '0');
        general_error_toastr();

        throw new Error('No reg type');
    };

    const handleRegTypeAuctioneer = () => {
        setIsAccountIncomplete(true);
        LoginService.logout();

        throw new Error('Auctioneer');
    };

    const isUserInactive = user => {
        return !user.active || user.active === '0' || user.active === 'false';
    };

    const handleUserInactive = () => {
        LoginService.logout();

        throw new Error('User inactive');
    };

    const setGAUser = () => {
        ReactGA.event({
            category: 'User',
            action: 'User Login',
        });
    };

    const setDataLayerUser = user => {
        window.dataLayer.push({
            user: {
                userId: user.user_id,
                lastLogin: get_utc_datetime(user.last_login),
                regDate: get_utc_datetime(user.reg_date),
                regType: user.reg_type,
                city: user.city,
                state: user.state_code,
                timezone: user.timezone,
                zipcode: user.zipcode,
                paymentMethod: user.payment_method,
                membershipType: user.membership_type,
                accountStatus: 'Active',
                biddingStatus: user.suspended == true ? 'Suspended' : 'Not Suspended',
                require_privacy: user.require_privacy,
            },
        });
    };

    const handleSuccessfulLogin = useCallback(async ({ otp_expires }) => {
        storage.setItem('st.authed', '1');

        try {
            const _user = await getUser({
                action: 'logged-in',
                ga_client_id: Cookies.get('_ga'),
                ga_session_id: Cookies.get('_ga_YM2Y56PMJX'),
            });

            if (!_user?.reg_type)
                return handleNoRegType();

            if (_user?.reg_type == AUCTIONEER.id)
                return handleRegTypeAuctioneer();

            if (isUserInactive(_user)) {
                return handleUserInactive();
            }

            setLoginStorageItems(_user);
            setGAUser();
            setDataLayerUser(_user);

            if (otp_expires) Cookies.set('otp_expires', otp_expires);

            return router.push('/');
        } catch (error) {
            console.error('Error retrieving user data after successful authentication:', error);
            LoginService.logout();
            storage.removeItem('st.authed');
            general_error_toastr();
        }
    }, []);

    const checkEmailVerified = async ({ value }) => {
        if (/\S+@\S+\.\S+/.test(value)) {
            try {
                const {
                    exists,
                    verified,
                    pending,
                    rejected,
                } = await fireCheckEmailVerified({
                    url: 'users/check/email-verified',
                    params: {
                        email: value,
                        scope: 'status',
                    },
                });

                setIsEmailVerified(!(exists && !verified));
                setIsVerificationPending(pending);
                setIsVerificationRejected(rejected);
            } catch (error) {
                console.error('Error verifying email', error);
                general_error_toastr();
            }
        }
    };

    const handleLoginError = err => {
        const { code } = err;

        if (code === NOT_AUTHORIZED_EXCEPTION || code === USER_NOT_FOUND_EXCEPTION) {
            setIsValidCredentials(false);
        } 
        general_error_toastr();
    };

    return (
        <LoginContext.Provider value={{
            handleSuccessfulLogin,
            runChecksAndLogin,
            requirePrivacy,
            requireTerms,
            requirePhone,
            requireMfa,
            requireAfp,
            phone,
            setRequirePhone,
            checkEmailVerified,
            handleLoginError,
            isEmailVerified,
            isVerificationPending,
            isVerificationRejected,
            isAccountIncomplete,
            isValidCredentials,
            setIsValidCredentials,
        }}>
            {children}
        </LoginContext.Provider>
    );
};

export default LoginContext;
