import React from 'react';
import { includes } from 'lodash';
import { useAuthActions } from 'hooks/useAuthActions';

interface History {
    replace(path: string): void;
}

interface LogoutProps {
    text?: string;
    className?: string;
    withRedirect?: string;
    withReload?: boolean;
    onClick?: () => void;
    history?: History;
}

const Logout: React.FC<LogoutProps> = (props) => {
    const { text = 'Log Out', className = 'btn btn-blue' } = props;
    const { logoutUser } = useAuthActions();

    const handleLogout = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        const { withRedirect, withReload, onClick } = props;

        logoutUser();
        
        if (withRedirect) {
            props.history.replace(withRedirect);
        } else if (withReload === true) {
            window.location.reload();
        }

        if (includes(window.location.pathname, '/account')) {
            window.location.replace('/');
        }

        if (onClick) onClick();
    };

    return (
        <a 
            href="#" 
            onClick={handleLogout} 
            className={className} 
            title={text} 
            id="logout"
        >
            {text}
        </a>
    );
};

export default Logout;