import App from 'next/app';
import React from 'react';
import { Provider } from 'react-redux';
import pageMap from 'utils/pageMap';
import PageHead from 'components/Page/PageHead';
import { APP_CONFIG } from 'Config';
import withRedux from 'next-redux-wrapper';
import reduxStore from 'utils/reduxStore';
import { persistLoginState, setAsRoute } from 'actions';
import { Amplify, Auth } from 'aws-amplify';
import { is_authed_async } from 'helpers/user';
import { CredentialsStorage } from 'helpers/CredentialStorage';
import middleware from 'utils/middleware';
import { FAILS_ACCOUNT, FAILS_AUTH, FAILS_GUEST, FAILS_UNAUTH } from 'Enums/middleware';
import { loginLink } from 'helpers/page';
import { handleRedirect } from 'components/Page/DoRedirect';
// import OneTrust from 'components/partials/OneTrust'
import { initGTM } from 'utils/registerJsServices';
import { trackNavigation } from '../helpers/analytics';
import LogoutCheckWrapper from '../components/wrappers/LogoutCheckWrapper';
import MaintenanceWrapper from '../components/wrappers/MaintenanceWrapper';
import AfpCheckWrapper from '../components/wrappers/AfpCheckWrapper';
import AfpInvitesWrapper from 'components/wrappers/AfpInvitesWrapper';
import UserIpService from 'services/UserIpService';
import { GlobalProvider } from 'context/GlobalContext';
import PageProvider from 'context/PageContext';
import { ToastContainer } from 'react-toastify';
import { AuctionNavigationProvider } from 'context/AuctionNavigationContext';
import { BidNotificationProvider } from 'context/BidNotificationContext';
import 'react-toastify/dist/ReactToastify.css';
import 'styles/bootstrap-4/_custom.scss';
import 'styles/common.less';
import 'styles/common.scss';
import 'styles/Main.less';
import 'styles/components/auction-image.less';
import 'styles/vendors/toastr.less';
import '@fortawesome/fontawesome-free/less/fontawesome.less';
import '@fortawesome/fontawesome-free/less/solid.less';
import '@fortawesome/fontawesome-free/less/regular.less';
import '@fortawesome/fontawesome-free/less/v4-shims.less';
import '@fortawesome/fontawesome-free/less/brands.less';

class MyApp extends App {
    //Initialize for when JS is disabled
    state = { showComponents: false, showOtherAuctions: false };

    handleShowOtherAuctions = async (showOtherAuctions = false) => {
        await this.setState({ showOtherAuctions });
    };

    handleRouteChangeComplete = () => {
        const { userId } = this.props;

        trackNavigation({ userId });
    };

    componentDidMount() {
        const firstPath = location.pathname.split('/')[1].toLowerCase();

        if (firstPath !== 'generate') {
            initGTM();
        }

        this.setState({ showComponents: true });

        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.getRegistrations().then((registrations) => {
                for (const registration of registrations) {
                    registration.unregister();
                }
            }).catch((err) => {
                // Service Worker registration failed
            });
        }

        this.props.router.events.on('routeChangeComplete', this.handleRouteChangeComplete);
    }

    componentWillUnmount() {
        this.props.router.events.off('routeChangeComplete', this.handleRouteChangeComplete);
    }

    render() {
        Amplify.register(Auth);
        Amplify.configure(APP_CONFIG.Auth);

        const {
            Component,
            pageProps = {},
            store,
            reg_type = undefined,
            is_authed = false,
            router = {},
            userId,
            pageData,
        } = this.props;

        const mapped_route = pageMap(router.route, { reg_type, userId });
        return (
            <GlobalProvider userId={userId}>
                <Provider store={store}>
                    <PageProvider initialPageData={pageData}>
                        <AuctionNavigationProvider>
                            <BidNotificationProvider>
                                <MaintenanceWrapper>
                                    <LogoutCheckWrapper>
                                        <AfpInvitesWrapper>
                                            <AfpCheckWrapper>
                                                <>
                                                    <PageHead route_path={router.asPath || ''}/>
                                                    <mapped_route.component
                                                        {...mapped_route.attributes}
                                                        showOtherAuctions={this.state.showOtherAuctions}
                                                        is_authed={is_authed}
                                                        {...pageProps}
                                                    >
                                                        {this.state.showComponents && (
                                                            <Component
                                                                {...pageProps}
                                                                location={router}
                                                                is_authed={is_authed}
                                                                handleShowOtherAuctions={this.handleShowOtherAuctions}
                                                                {...mapped_route.attributes}
                                                            />)}
                                                    </mapped_route.component>
                                                </>
                                                {/*<OneTrust />*/}
                                            </AfpCheckWrapper>
                                        </AfpInvitesWrapper>
                                    </LogoutCheckWrapper>
                                </MaintenanceWrapper>
                                <ToastContainer
                                    position="top-right"
                                    autoClose={5000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnFocusLoss
                                    draggable
                                    pauseOnHover
                                />
                            </BidNotificationProvider>
                        </AuctionNavigationProvider>
                    </PageProvider>
                </Provider>
            </GlobalProvider>
        );
    }
}

export function reportWebVitals(metric) {
    switch (metric.name) {
        case 'Next.js-route-change-to-render':
            break;
        default:
            break;
    }
}

MyApp.getInitialProps = async (appContext) => {
    const { Component, ctx, router } = appContext;

    const credentialsStorage = new CredentialsStorage(ctx, {
        domain: APP_CONFIG.COOKIE_DOMAIN,
        path: '/',
        expires: 365,
        secure: true, //! APP_CONFIG.DEV, // true or false
    });

    const {
        asPath = '/', // for example /about?test=one
        store: { dispatch },
    } = ctx;

    await UserIpService.getUserIp(ctx.req);

    APP_CONFIG.Auth.storage = credentialsStorage;

    Amplify.register(Auth);
    Amplify.configure(APP_CONFIG.Auth);

    await Promise.all([
        // Track asPath which is the path with query for example /about?test=one
        dispatch(setAsRoute(asPath)),

        // Propagate user state from cookies into redux
        dispatch(persistLoginState({
            has_imports: credentialsStorage.getItem('st.has_imports') == '1',
            user_id: credentialsStorage.getItem('st.user_id'),
            payment_method: credentialsStorage.getItem('st.payment_method'),
            email: credentialsStorage.getItem('st.email'),
            timezone: credentialsStorage.getItem('st.timezone') || 'America/Phoenix',
            reg_type: credentialsStorage.getItem('st.reg_type'),
            show: credentialsStorage.getItem('st.thingamabob.show'),
            position: credentialsStorage.getItem('st.thingamabob.position'),
            tab: credentialsStorage.getItem('st.thingamabob.tab'),
        })),
    ]);

    const is_authed = await is_authed_async();
    let pageProps = {};
    const store = ctx.store.getState();

    const passed_middleware = middleware(Component, store);

    if (passed_middleware != true) {
        const { res } = ctx;
        const { middleware_redirect } = Component;

        switch (passed_middleware) {
            case FAILS_ACCOUNT:
                handleRedirect(middleware_redirect || '/account/dashboard', res);
                return { passed_middleware };
            case FAILS_AUTH:
                const Location = loginLink({ redirect_to: middleware_redirect || router.asPath });
                handleRedirect(Location, res);
                return { passed_middleware };
            case FAILS_UNAUTH:
            case FAILS_GUEST:
                handleRedirect(middleware_redirect || '/', res);
                return { passed_middleware };
        }
    }

    if (passed_middleware == true && Component.getInitialProps) {
        pageProps = await Component.getInitialProps(ctx, store, is_authed) || {};
    }

    let pageData = {};
    if (Component.getInitialProps) {
        const componentProps = await Component.getInitialProps(ctx, store, is_authed);
        if (componentProps.pageData) {
            pageData = componentProps.pageData;
        }
    }

    return { pageProps, is_authed, reg_type: store.user.reg_type, userId: credentialsStorage.getItem('st.user_id'), pageData };
};

export default withRedux(reduxStore)(MyApp);