import {
    AUCTION_CANCEL,
    AUCTION_CANCEL_FAIL,
    AUCTION_CANCEL_FORM,
    AUCTION_CANCEL_SUCCESS,
    AUCTION_IMAGE_GET,
    AUCTION_IMAGE_GET_FAIL,
    AUCTION_IMAGE_GET_SUCCESS,
    AUCTION_POSTING_FORM_CLEAR,
    AUCTION_POSTING_INPUT,
    AUCTION_SAVE_DETAILS,
    AUCTION_SAVE_DETAILS_FAIL,
    AUCTION_SAVE_DETAILS_SUCCESS,
    CLEAR_AUCTION_DATA,
    CLEAR_AUCTION_FILTERS,
    CLEAR_HOME_AUCTION_DATA,
    GET_AUCTION,
    GET_AUCTION_FAIL,
    GET_AUCTION_SEARCH_RADIUS,
    GET_AUCTION_SEARCH_RADIUS_FAIL,
    GET_AUCTION_SEARCH_RADIUS_SUCCESS,
    GET_AUCTION_SUCCESS,
    GET_AUCTIONS_ENDING_SOON,
    GET_AUCTIONS_ENDING_SOON_FAIL,
    GET_AUCTIONS_ENDING_SOON_SUCCESS,
    GET_CATEGORIES,
    GET_CATEGORIES_FAIL,
    GET_CATEGORIES_SUCCESS,
    GET_COMING_SOON_AUCTIONS,
    GET_COMING_SOON_AUCTIONS_FAIL,
    GET_COMING_SOON_AUCTIONS_SUCCESS,
    GET_ONLINE_AUCTIONS,
    GET_ONLINE_AUCTIONS_FAIL,
    GET_ONLINE_AUCTIONS_SUCCESS,
    GET_TOP_AUCTIONS,
    GET_TOP_AUCTIONS_FAIL,
    GET_TOP_AUCTIONS_SUCCESS,
    ONLINE_FILTER_SEARCH,
    ONLINE_FILTER_SEARCH_FAIL,
    ONLINE_FILTER_SEARCH_SUCCESS,
    POST_AUCTION_SUCCESS,
    SAVE_AUCTION_AS_DRAFT_SUCCESS,
    SET_FILTER_CATEGORY,
    UPDATE_AUCTIONS_ENDING_SOON,
    UPDATE_TOP_AUCTIONS,
    EXPIRES_IN,
} from 'actions/AuctionTypes';
import { get, range } from 'lodash';
import { BID_MANAGER_UPDATE, BID_MANAGER_UPDATE_FAIL, BID_MANAGER_UPDATE_SUCCESS } from 'actions/BidManagerTypes';
import {
    FACILITY_MANAGER_GET_AUCTION_DETAILS,
    FACILITY_MANAGER_GET_AUCTION_DETAILS_FAIL,
    FACILITY_MANAGER_GET_AUCTION_DETAILS_SUCCESS,
    FACILITY_MANAGER_METRICS,
    FACILITY_MANAGER_METRICS_FAIL,
    FACILITY_MANAGER_METRICS_SUCCESS,
    FACILITY_MANAGER_TOP_AUCTIONS,
    FACILITY_MANAGER_TOP_AUCTIONS_FAIL,
    FACILITY_MANAGER_TOP_AUCTIONS_SUCCESS,
} from 'actions/FacilityManagerTypes';
import moment from 'moment';
import { generate_date_object, user_unformatted_date, user_unformatted_time, user_datetime_raw, user_formatted_date_time } from 'helpers/date';
import { general_success_toastr } from '../components/partials/Notification';

const INITIAL_STATE = {
    loading: false,
    online_search_loading: false,
    coming_soon_loading: false,
    error: null,
    coming_soon_error: null,
    auction: {},
    auctions_live: [],
    auctions_coming_soon: [],
    top_auctions: undefined,
    top_auction_loading: false,
    top_auction_error: false,
    auctions_ending_soon: undefined,
    auctions: [],
    upcoming_auctions: [],
    upcoming_total_records: 0,
    cleanout_times: [
        {label: '24 Hours', value: '24'},
        {label: '48 Hours', value: '48'},
        {label: '72 Hours', value: '72'},
    ],
    categories: [],
    auction_types: [
        {label: 'Lien Unit', value: '1', slug: 'lien-unit'},
        {label: 'Non-Lien Unit/Manager Special', value: '2', slug: 'non-lien-unit-manager-special'},
        {label: 'Charity Unit', value: '3', slug: 'charity-unit'},
    ],
    unit_widths: range(1, 76).map(num => ({label: num + '', value: num + ''})),
    unit_length: range(1, 76).map(num => ({label: num + '', value: num + ''})),
    auction_metrics: {
        active: '0',
        bid_value: '0',
        canceled: '0',
        posted: '0',
        sold: '0',
    },
    auction_metrics_loading: false,
    auction_metrics_error: false,
    draft_id: false,
    posting_form_loading: null,
    posting_form: {
        //form defaults
        type_id: '1',
        cleanout_time: '72',
        //default date and time for the form. This is also below in case AUCTION_POSTING_INPUT
        active_date: moment().format('MM/DD/YYYY'),
        // expire_date: moment().format('MM/DD/YYYY'),
        active_time: moment().format('hh:mm A'),
        // expire_time: moment().format('hh:mm A')
    },
    posting_form_defaults: {
        //form defaults
        type_id: '1',
        cleanout_time: '72',
        //default date and time for the form. This is also below in case AUCTION_POSTING_INPUT
        active_date: moment().format('MM/DD/YYYY'),
        // expire_date: moment().format('MM/DD/YYYY'),
        active_time: moment().format('hh:mm A'),
        // expire_time: moment().format('hh:mm A')
    },
    posting_form_updated: false,
    // If the order of this changes please update the Live auction TopSearch Component include. It needs to exclude 'keyword'
    searchTypes: [
        {id: 'zipcode', name: 'Zip Code/Postal Code'},
        {id: 'state', name: 'State/Province'},
        {id: 'city', name: 'City'},
        {id: 'facility', name: 'Facility Name'},
        {id: 'keyword', name: 'Keyword(s)'},
    ],
    searchRadius: [
        {id: 'entire', name: 'Exact'},
        {id: 5, name: '5 mi'},
        {id: 10, name: '10 mi'},
        {id: 25, name: '25 mi'},
        {id: 50, name: '50 mi'},
        {id: 100, name: '100 mi'},
        {id: 150, name: '150 mi'},
        {id: 200, name: '200 mi'},
        {id: 500, name: '500 mi'},
    ],
    bidmanager_bid_updated: false,
    // TODO::maybe move this to a global alert reducer
    bid_message_map: [
        {slug: 'nobid-minimum', subject: 'Sorry, try again!', body: 'You must bid at least the minimum amount.', type: 'error'},
        {slug: 'outbid-match', subject: 'Bid accepted!', body: 'Your bid has been recorded.', type: 'success'},
        {slug: 'bid-success', subject: 'Bid accepted!', body: 'Your bid has been recorded.', type: 'success'},
        {slug: 'bid-high-bidder', subject: 'You are the high bidder!', body: 'On a roll!', type: 'success'},
        {slug: 'outbid', subject: 'You\'ve been outbid.', body: 'Bid again!', type: 'error'},
        {slug: 'outbid-match', subject: 'Tied with another bidder. Bid again!', body: '', type: 'warning'},
        {slug: 'nobid-minimum', subject: 'Oops,', body: 'something happened. Your bid was unsuccessful. You must bid at least the minimum amount.', type: 'error', buttonText: 'Go Back and Try Again'},
        {
            slug: 'user-payment',
            subject: 'Oops!',
            body: ' It seems we do not have updated payment information for you. Please update your payment method in your Account Settings prior to bidding.',
            type: 'error',
            buttonText: 'Go to My Account',
            buttonLink: '/account/settings/',
        },
        {slug: 'bid-increment', subject: 'Your bid was unsuccessful.', body: 'Must be a multiple of $10.', type: 'error'},
        {slug: 'auction-expired', subject: 'Your bid was unsuccessful.', body: 'This auction has ended.', type: 'error'},
        {slug: 'max-bid-success', subject: 'Bid accepted!', body: 'Your bid has been recorded.', type: 'success'},
        {slug: 'max-bid-failed', subject: 'Sorry, try again!', body: 'You must bid at least the minimum amount.', type: 'error'},
        {slug: 'max-bid-low', subject: 'Sorry, try again!', body: 'You must bid at least the minimum amount.', type: 'error'},
        {slug: 'nobid-internal', subject: 'Oops!', body: 'Something happened try again.', type: 'error', buttonText: 'Go Back and Try Again'},
        {slug: 'auction-winner', subject: 'Hey, you won this one!', body: 'Congratulations!', type: 'success'},
        {slug: 'user-deactivated', subject: 'Oops!', body: 'Your account is not active. You cannot bid.', type: 'error', buttonText: 'Go Back'},
        {
            slug: 'user-restriction-won-auctions',
            subject: 'Not eligible to bid on this auction due to restrictions for:',
            body: <><br/>• Won auctions - your membership allows you to bid on auctions $1000+ only if you have a certain number of won auctions. Restriction removed under certain circumstances.</>,
            type: 'error',
            hyperlink: {
                text: 'Learn more about the benefits of an upgraded membership.',
                url: '/pro-membership-upgrade',
            },
            buttonText: 'Manage Membership',
            buttonLink: '#',
        },
        {
            slug: 'user-restriction-radius',
            subject: 'Not eligible to bid on this auction due to restrictions for:',
            body: <><br/>• Radius - your membership allows you to bid on auctions within a limited radius.</>,
            type: 'error',
            hyperlink: {
                text: 'Learn more about the benefits of an upgraded membership.',
                url: '/pro-membership-upgrade',
            },
            buttonText: 'Manage Membership',
            buttonLink: '#',
        },
        {
            slug: 'user-restriction-high-bidder',
            subject: 'Not eligible to bid on this auction due to restrictions for:',
            body: <><br/>• Leaderboard - your membership allows you to be the high bidder within a limited number of auctions at the same time. Restriction removed under certain circumstances.</>,
            type: 'error',
            hyperlink: {
                text: 'Learn more about the benefits of an upgraded membership.',
                url: '/pro-membership-upgrade',
            },
            buttonText: 'Manage Membership',
            buttonLink: '#',
        },
    ],
    auction_cancel_form: {},
    auction_cancel_loading: false,
    auction_cancel_error: false,
    auction_images: [],
    auction_images_loading: false,
    auction_images_error: false,
    auction_posted_success: false,
    auction_draft_saved: false,
    file_upload_attrs: {
        inc: 0,
        percentage_progress: 0,
        files_updated: 0,
        current_percentage: 0,
        goal_percentage: 100,
        file_name: '',
        uploading: false,
    },
    navigatingToAuction: false,
};

export const get_bid_message = (search_slug, default_value = {slug: '', subject: '', body: '', type: ''}) => {
    if (!search_slug) {
        return default_value; //default
    }

    const found = INITIAL_STATE.bid_message_map.find((message) => (message.slug == search_slug));

    if (!found) {
        return default_value; //default
    }

    return found;
};

export default (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case GET_AUCTION:
            return {...state, loading: true, error: null};
        case CLEAR_AUCTION_DATA:
            return {...state, loading: false, error: null, auction: {}};
        case CLEAR_HOME_AUCTION_DATA:
            state.top_auctions = [];
            state.auctions_ending_soon = [];
            return {...state, loading: false, error: null};
        case GET_AUCTION_SUCCESS:
            const {
                auction: get_auction_success_auction,
                facility: get_auction_success_facility = {},
            } = action.payload || {};

            if (get_auction_success_auction.is_expired && get_auction_success_auction.status_id == '1') {

                // Auction expired with bids on it
                if (parseInt(get_auction_success_auction.total_bids)>0){
                    get_auction_success_auction.status_id = '2';
                    get_auction_success_auction.status_name = 'Sold';
                }else {
                    // Auction expired without bids
                    get_auction_success_auction.status_id = '3';
                    get_auction_success_auction.status_name = 'Unsold';
                }

                get_auction_success_auction.countdown = {
                    'days': 0,
                    'hours': 0,
                    'minutes': 0,
                    'seconds': 0,
                };
            }

            return {...state, auction: {...get_auction_success_auction, facility: get_auction_success_facility}, loading: false, error: null};
        case SET_FILTER_CATEGORY:
            return {...state, filter_categories: checkboxLists(get(state, 'filter_categories', []), action.payload)};
        case CLEAR_AUCTION_FILTERS:
            return {...state, filter_categories: [], filter_types: ['1', '2', '3'], filter_unit_contents: ''};
        case GET_AUCTION_FAIL:
            return {...state, loading: false, error: 'Failed to retrieve the auction details'};
        case GET_ONLINE_AUCTIONS:
            return {...state, online_search_loading: true, error: null, auctions: [], total_records: 0};
        case GET_ONLINE_AUCTIONS_SUCCESS:
            let auctions = get(action, 'payload.auctions', []);

            return {
                ...state,
                auctions: auctions,
                metrics: get(action, 'payload.metrics', {}),
                total_records: get(action, 'payload.total_records', 0),
                current_page: get(action, 'payload.page.num', '1'),
                online_search_loading: false,
            };
        case GET_ONLINE_AUCTIONS_FAIL:
            return {...state, error: 'Failed to retrieve auctions.', online_search_loading: get(action, 'payload.message') == 'canceled'};
        case GET_COMING_SOON_AUCTIONS:
            return {...state, upcoming_auctions: [], coming_soon_loading: true, coming_soon_error: null, upcoming_total_records: 0};
        case GET_COMING_SOON_AUCTIONS_SUCCESS:
            let coming_soon_auctions = action.payload.auctions ? action.payload.auctions : [];
            return {
                ...state,
                upcoming_auctions: coming_soon_auctions,
                upcoming_total_records: get(action, 'payload.total_records', '0'),
                upcoming_current_page: get(action, 'payload.page.num', '0'),
                coming_soon_loading: false,
            };
        case GET_COMING_SOON_AUCTIONS_FAIL:
            return {...state, coming_soon_error: 'Failed to retrieve coming soon auctions.', coming_soon_loading: false};
        case ONLINE_FILTER_SEARCH:
            return {...state, loading: true};
        case ONLINE_FILTER_SEARCH_SUCCESS:
            return {...state, auctions: action.payload, loading: false};
        case ONLINE_FILTER_SEARCH_FAIL:
            return {...state, error: 'Failed to filter auctions.', loading: false};
        case GET_CATEGORIES:
            return {...state, categories: [], loading: true};
        case GET_CATEGORIES_SUCCESS:
            const {auction_categories = []} = action.payload;
            return {...state, categories: auction_categories, loading: false};
        case GET_CATEGORIES_FAIL:
            return {...state, error: 'Failed to retrieve auction categories', loading: false};
        case GET_AUCTION_SEARCH_RADIUS:
            return {...state, loading: true};
        case GET_AUCTION_SEARCH_RADIUS_SUCCESS:
            return {...state, searchRadius: action.payload, loading: false};
        case GET_AUCTION_SEARCH_RADIUS_FAIL:
            return {...state, error: 'Failed to retrieve auction search radius options', loading: false};
        case GET_TOP_AUCTIONS:
            return {...state, loading: true};
        case UPDATE_TOP_AUCTIONS:
            return {...state, loading: false};
        case GET_TOP_AUCTIONS_SUCCESS:
            return {...state, top_auctions: action.payload, loading: false};
        case GET_TOP_AUCTIONS_FAIL:
            return {...state, error: 'Failed to retrieve auctions.', loading: false};
        case GET_AUCTIONS_ENDING_SOON:
            return {...state, loading: true};
        case GET_AUCTIONS_ENDING_SOON_SUCCESS:
            return {...state, auctions_ending_soon: action.payload, loading: false};
        case GET_AUCTIONS_ENDING_SOON_FAIL:
            return {...state, error: 'Failed to retrieve auctions.', loading: false};
        case UPDATE_AUCTIONS_ENDING_SOON:
            return {...state, loading: false};
        case BID_MANAGER_UPDATE:
            return {...state, loading: true, error: false, bidmanager_bid_updated: false};
        case BID_MANAGER_UPDATE_SUCCESS:
            return {...state, loading: false, error: false, bidmanager_bid_updated: true};
        case BID_MANAGER_UPDATE_FAIL:
            return {...state, loading: false, error: action.payload, bidmanager_bid_updated: true};
        case FACILITY_MANAGER_TOP_AUCTIONS:
            return {...state, top_auction_loading: true, top_auction_error: false, top_auctions: []};
        case FACILITY_MANAGER_TOP_AUCTIONS_SUCCESS:
            return {...state, top_auction_loading: false, top_auctions: get(action.payload, 'auctions', [])};
        case FACILITY_MANAGER_TOP_AUCTIONS_FAIL:
            return {...state, top_auction_loading: false, top_auction_error: action.error};
        case FACILITY_MANAGER_METRICS:
            const auction_metrics_default = {
                active: '0',
                bid_value: '0',
                canceled: '0',
                posted: '0',
                sold: '0',
            };

            return {...state, auction_metrics_loading: true, auction_metrics_error: false, auction_metrics: auction_metrics_default};
        case FACILITY_MANAGER_METRICS_SUCCESS:
            return {...state, auction_metrics_loading: false, auction_metrics: get(action, 'payload.metrics.auctions')};
        case FACILITY_MANAGER_METRICS_FAIL:
            return {...state, auction_metrics_loading: false, auction_metrics_error: action.error};
        case AUCTION_POSTING_FORM_CLEAR:
            return {
                ...state,
                editing_relist: false,
                posting_form_updated: false,
                posting_form: state.posting_form_defaults,
            };
        case AUCTION_POSTING_INPUT:
            if (action.clear_all === true) {
                return {
                    ...state,
                    editing_relist: false,
                    posting_form_updated: false,
                    posting_form: state.posting_form_defaults,
                };
            }
            return {...state, posting_form_updated: true, posting_form: {...state.posting_form, [`${action.name}`]: action.value}};
        case AUCTION_SAVE_DETAILS:
            return {...state, form_data_saving: true, form_data_saving_error: false};
        case AUCTION_SAVE_DETAILS_SUCCESS:
            state.draft_id = action.payload.draft_id;
            state.posting_form.draft_id = action.payload.draft_id;
            return {...state, form_data_saving: false, payload: action.payload};
        case AUCTION_SAVE_DETAILS_FAIL:
            return {...state, form_data_saving: false, form_data_saving_error: action.error};
        case FACILITY_MANAGER_GET_AUCTION_DETAILS:

            if (action.trigger_reloading) {
                state.posting_form_loading = true;
                state.posting_form = state.posting_form_defaults;
            }

            return {
                ...state,
                error: false,
            };
        case FACILITY_MANAGER_GET_AUCTION_DETAILS_SUCCESS:
            const {
                auction_draft = false,
                auction_import = false,
                auction = false,
            } = action.payload;

            let the_auction = {active_date: '', expire_date: ''};
            let auction_type = 'auction_draft';

            if (auction_draft) {
                the_auction = auction_draft;
            } else if (auction_import) {
                auction_type = 'import';
                the_auction = {...auction_import, cleaning_deposit: undefined};
            } else if (auction) {
                auction_type = 'auction';
                the_auction = auction;
            }

            let editing_relist = false;
            if (action.relist_id && action.relist_id != '0') {
                editing_relist = true;
                the_auction.active_date = generate_date_object();
                the_auction.expire_date = null;
            }

            let {
                tenant = {},
                active_date,
                expire_date = '',
                type_id = 1,
            } = the_auction;

            let original_active_date = active_date;

            const _active_date = user_formatted_date_time(active_date);
            const _expire_date = user_unformatted_date(expire_date);

            if (auction_type == 'auction_draft') {
                // if auction type is draft and the active date is in the past set the active date to today

                if (moment(_active_date).isBefore(moment())) {
                    active_date = generate_date_object();
                }

                if (moment(_expire_date).isBefore(moment().subtract(1, 'd'))) {
                    expire_date = '';
                }

            } else if (auction_type == 'import') {

                // if auction type is import and the expire date is in the past set the expire date to today

                if (moment(_expire_date).isBefore(moment())) {
                    expire_date = '';
                } else {

                    // Imported auctions should be the user timezone (utc datetime assumed on import)
                    expire_date = generate_date_object(user_datetime_raw(expire_date));
                    active_date = generate_date_object(user_datetime_raw(active_date));
                }

                the_auction.cleanout_time = undefined;
                the_auction.unit_additional = undefined;
                the_auction.cleaning_deposit = undefined;
                the_auction.sales_tax = undefined;
            }

            if (action.date_formatting === false) {
                return {
                    ...state, posting_form: {
                        ...the_auction,
                        ...tenant,
                        type_id: !type_id || type_id == '0' ? '1' : type_id,
                    }, posting_form_loading: false,
                };
            }

            if (action.withCleanup === true) {
                const the_auction_categories = the_auction.categories || [];

                the_auction.categories = the_auction_categories.map(category => {
                    if (category.category_id) {
                        return category.category_id;
                    } else if (typeof category == 'string') {
                        return category;
                    }

                    return false;
                });

            }

            return {
                ...state, editing_relist, posting_form: {
                    ...the_auction,
                    ...tenant,
                    type_id: !type_id || type_id == '0' ? '1' : type_id,

                    // Date objects that would otherwise be overwritten below. This need to be formatted
                    // the way they are for the auction posting form.
                    expire_date_obj: expire_date,

                    // This active date value could change if the original value is in the past. Since this can happen
                    // the original_active_date value can be used for pages like the auction posting preview page.
                    active_date_obj: active_date,
                    original_active_date,
                    // these values change depending on what the user enters in the form
                    active_date: user_unformatted_date(active_date),
                    expire_date: user_unformatted_date(expire_date),
                    active_time: user_unformatted_time(active_date),
                    expire_time: user_unformatted_time(expire_date),
                    full_expire_date: expire_date,
                    auction_type,
                }, posting_form_loading: false,
            }; // posting_form: action.payload.auction
        case FACILITY_MANAGER_GET_AUCTION_DETAILS_FAIL:
            return {...state, posting_form_loading: false, error: action.error};
        case AUCTION_CANCEL_FORM:
            if (action.clearAll == true) {
                return {...state, auction_cancel_form: {}};
            }
            return {...state, auction_cancel_form: {...state.auction_cancel_form, [action.name]: action.value}};
        case AUCTION_CANCEL:
            return {...state, auction_cancel_loading: true, auction_cancel_error: false};
        case AUCTION_CANCEL_SUCCESS:
            return {...state, auction_cancel_loading: false};
        case AUCTION_CANCEL_FAIL:
            return {...state, auction_cancel_loading: false, auction_cancel_error: action.error};
        // todo after the api is up work on this section of code.
        case AUCTION_IMAGE_GET:
            return {...state, auction_images_loading: true, auction_images_error: false/*, auction_images: []*/};
        case AUCTION_IMAGE_GET_SUCCESS:
            return {...state, auction_images_loading: false, auction_images: action.payload.auction.images};
        case AUCTION_IMAGE_GET_FAIL:
            return {...state, auction_images_loading: false, auction_images_error: action.error};
        case POST_AUCTION_SUCCESS:
            return {...state, auction_posted_success: true};
        case SAVE_AUCTION_AS_DRAFT_SUCCESS:
            return {...state, auction_draft_saved: true};
        case EXPIRES_IN:
            return {...state, EXPIRES_IN: action.value};
        default:
            return state;
    }
};

function checkboxLists(list, value) {
    if (list.indexOf(value) < 0) {
        return [...list, value];
    }

    return list.filter(item => {
        return item !== value;
    });
}