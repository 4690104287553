import React from 'react';
import Link from 'components/Link';
import { connect } from 'react-redux';
import { get } from 'lodash';
import AuctionTile from './AuctionTile';
import { useAuctionNavigation } from 'context/AuctionNavigationContext';
import 'styles/components/auction-tile.less';

const OtherAuctions = (props) => {
    const { navigatingToAuction } = useAuctionNavigation();
    
    const renderViewMore = () => {
        const facility_id = get(props, 'auctions.0.facility_id', 'missing');

        if (props.auctions && props.auctions.length > 6) {
            return (
                <div className="row text-center marginB15">
                    <Link to={`/facilities/${facility_id}`} className="btn btn-regular btn-blue see-more">See More at This Facility</Link>
                </div>
            );
        }

        return null;
    };

    const {
        show = false,
        auction_loading,
        auction_error,
    } = props;

    // NOTE :: Facility_loading is not here because it makes this area flash on the auction details page
    // when the api is called
    if (!show || auction_loading || (auction_error && (!props.auctions || props.auctions.length === 0)) || navigatingToAuction) {
        return null;
    }

    if (props.auctions && props.auctions.length > 1) {
        return (
            <div className={`other-auctions-container other-auctions-bg ${!show && 'hidden'}`}>
                <div className="container">
                    <h2 className="section-title">Other Auctions at This Facility</h2>

                    <div className="auctions-container">
                        <div className="auction-tiles-special-center">
                            {props.auctions.filter((auction) => auction.auction_id != props.auction_id).map((auction, i) => {
                                if (i > 5) {
                                    return null;
                                }

                                return (
                                    <AuctionTile key={auction.auction_id} auction={auction} otherAuctions />
                                );
                            })}
                        </div>
                    </div>
                    <div className="clearfix" />
                    {renderViewMore()}
                </div>
            </div>
        );
    }

    return null;
};

const mapStateToProps = ({ page, auction, facility }) => ({
    containerClass: page.data.containerClass,
    auction_id: auction.auction.auction_id,
    auctions: facility.auctions,
    auction_loading: auction.loading,
    auction_error: auction.error,
    facility_loading: facility.loading_auctions,
    facility_error: facility.error,
});

export default connect(mapStateToProps, {})(OtherAuctions);