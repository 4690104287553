import React from 'react';
import BaseSelect from './BaseSelect';
import { Field, FieldProps } from 'formik';

interface BaseSelectProps {
    name: string
    label?: string
    error?: string
    className?: string
    children: React.ReactNode
    [key: string]: any
}

interface SelectProps extends Omit<BaseSelectProps, 'error'> {
    formik?: boolean
    nested?: boolean
}

export const Select: React.FC<SelectProps> = ({
    formik,
    nested = false,
    name,
    children,
    ...props
}) => {
    if (formik) {
        return (
            <Field name={name}>
                {({ field, form }: FieldProps) => {
                    const fieldGroup = name?.split('.')[0];
                    const fieldName = name?.split('.')[1];
                    const touched = !nested 
                        ? form.touched[name] 
                        : form.touched?.[fieldGroup]?.[fieldName];
                    const errorString = !nested 
                        ? form.errors[name] 
                        : form.errors?.[fieldGroup]?.[fieldName];

                    return (
                        <BaseSelect 
                            name={name}
                            error={touched && typeof errorString === 'string' ? errorString : undefined}
                            {...props} 
                            {...field}
                        >
                            {children}
                        </BaseSelect>
                    );
                }}
            </Field>
        );
    }

    return (
        <BaseSelect 
            name={name}
            {...props}
        >
            {children}
        </BaseSelect>
    );
};