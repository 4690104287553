import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useService } from 'hooks/useService';
import storage from 'helpers/storage';
import { Country } from 'types/Address';
import { User } from 'types/User';
import { is_authed_async } from 'helpers/user';

interface GlobalContextType {
    countries: Country[];
    user: User;
    setCountries: (countries: Country[]) => void;
    setUser: (user: User) => void;
    setLoading: (loading: boolean) => void;
    getUser: (params?: any) => Promise<User>;
    getCountries: () => Promise<Country[]>;
    loading: boolean,
    userLoading: boolean,
    countriesLoading: boolean,
}

export const GlobalContext = createContext<GlobalContextType>({} as GlobalContextType);

export const useGlobalContext = () => useContext(GlobalContext);

interface GlobalProviderProps {
    userId: string;
    children: ReactNode;
}

interface UserResponse {
    user: User;
}

interface CountriesResponse {
    countries: Country[];
}

export const GlobalProvider: React.FC<GlobalProviderProps> = ({ userId, children }) => {
    const [{ loading: userLoading }, fireGetUser] = useService<UserResponse>();
    const [{ loading: countriesLoading }, fireGetCountries] = useService<CountriesResponse>();
    const [loading, setLoading] = useState<boolean>(false);
    const [user, setUser] = useState<User>({} as User);
    const [countries, setCountries] = useState<Country[]>([]);

    const setPaymentMethodInStorage = (paymentMethod: boolean) => {
        storage.setItem('st.payment_method', paymentMethod !== false ? '1' : '0');
    };

    const getUser = async (params?: any): Promise<User> => {
        if (loading) return user;

        setLoading(true);
        try {
            const { user: _user } = await fireGetUser({
                url: 'users/account',
                params: {
                    scope: 'account-settings-form',
                    ...params,
                },
            });

            const isAuthenticated = await is_authed_async();
            
            const userWithAuth = {
                ..._user,
                authed: isAuthenticated,
            };

            setUser(userWithAuth);
            setPaymentMethodInStorage(_user.payment_method);

            return userWithAuth;
        } catch (error) {
            console.error('Failed to fetch user', error);
            throw error;
        } finally {
            setLoading(false);
        }
    };

    const getCountries = async (): Promise<Country[]> => {
        setLoading(true);
        try {
            const { countries } = await fireGetCountries({
                url: 'lists/countries',
            });
            setCountries(countries);
            return countries;
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (userId && !user.user_id && !loading) {
            getUser();
        }
    }, [userId]);

    return (
        <GlobalContext.Provider
            value={{
                countries,
                user,
                setCountries,
                setUser,
                setLoading,
                getUser,
                getCountries,
                loading,
                userLoading,
                countriesLoading,
            }}
        >
            {children}
        </GlobalContext.Provider>
    );
};

export default GlobalProvider;