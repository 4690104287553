import { useEffect } from 'react';
import CountdownTimer from './CountdownTimer';
import { useTimerContext } from 'context/TickContext';
import { get_user_datetime } from 'helpers/date';
import moment from 'moment';

const StartsCloses = ({ 
    auction, 
    handleExpiredTime, 
    postReview = false, 
    bidManager = false,
    auctionTile = false,
}) => {
    const { startTimer, stopTimer } = useTimerContext();
    
    const {
        upcoming,
        expire_date,
        active_date,
        current_date,
        status_id,
        active_date_obj: activeDate,
        expire_date_obj: expireDate,
        is_expired: auctionExpired,
    } = auction;

    const expire = postReview ? expireDate : expire_date;
    const upcomingDate = postReview ? activeDate : active_date;

    useEffect(() => {
        startTimer();

        return () => {
            stopTimer();
        };
    }, []);

    if (postReview) {
        return (
            <CountdownTimer
                from={!upcoming ? moment(get_user_datetime(expire)) : moment(get_user_datetime(upcomingDate))}
                upcoming={upcoming}
                current={get_user_datetime(current_date)}
                handleExpiredTime={handleExpiredTime}
            />
        );
    }

    switch (status_id) {
        case '1':
            return (
                <CountdownTimer
                    from={!upcoming ? moment(get_user_datetime(expire)) : moment(get_user_datetime(upcomingDate))}
                    upcoming={upcoming}
                    auctionExpired={auctionExpired}
                    current={get_user_datetime(current_date)}
                    handleExpiredTime={handleExpiredTime}
                    bidManager={bidManager}
                    auctionTile={auctionTile}
                />
            );
        case '2':
        case '3':
        case '4':
        default:
            return null;
    }
};

export default StartsCloses;