export const SET_GLOBAL_ALERT= 'set_global_alert';
export const CLEAR_ALERT= 'clear_alerts';
export const SCREEN_RESIZE = 'screen_resize';
export const SET_DASHBOARD_PAGE = 'set_dashboard_page';

// Specific alert messages
export const FACILITY_BOOKMARK_ADDED = 'facility_bookmark_added';
export const FACILITY_BOOKMARK_REMOVED = 'facility_bookmark_removed';

// Page sizing
export const SET_PAGE_CONTAINER_CLASS = 'set_page_container';

export const PAGE_AS_ROUTE = 'page_as_route';