import ReactGA from 'react-ga4';

export const trackNavigation = ({ userId = '' }) => {
    ReactGA.event({
        category: 'User',
        action: 'Navigation',
    });

    if (window.dataLayer) {
        window.dataLayer.push({
            user: {
                userId,
            },
        });
    }

};
