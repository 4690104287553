import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';

interface PageDataInner {
    title?: string;
    h1?: string;
    description?: string;
    marginBottom?: string;
    containerClass?: string;
}

interface PageData {
    title: string;
    description?: string;
    loading: boolean;
    error: null | string;
    og: {
        description?: string;
        image?: string;
    };
    data: PageDataInner;
}

interface SetPageProps extends Partial<PageDataInner> {
    og?: {
        description?: string;
        image?: string;
    };
}

interface PageContextType {
    pageData: PageData;
    setPage: (data: SetPageProps) => void;
    resetPage: () => void;
}

const defaultPageDataInner: PageDataInner = {
    title: '',
    h1: '',
    description: '',
    marginBottom: 'pageTitle marginB20',
    containerClass: 'container',
};

const defaultPageData: PageData = {
    title: 'Online Storage Unit Auctions | StorageTreasures.com',
    description: '',
    loading: false,
    error: null,
    og: {
        description: '',
        image: '',
    },
    data: defaultPageDataInner,
};

export const PageContext = createContext<PageContextType>({
    pageData: defaultPageData,
    setPage: () => {},
    resetPage: () => {},
});

export const usePage = () => useContext(PageContext);

interface PageProviderProps {
    children: ReactNode;
    initialPageData?: Partial<PageData>;
}

const getFormattedPageTitle = (title: string | undefined): string => {
    return (title || 'Online Storage Unit Auctions') + ' | StorageTreasures.com';
};

export const PageProvider: React.FC<PageProviderProps> = ({ children, initialPageData }) => {
    const [pageData, setPageDataValue] = useState<PageData>({
        ...defaultPageData,
        title: getFormattedPageTitle(initialPageData?.title),
        description: initialPageData?.description || defaultPageData.description,
        og: initialPageData?.og || defaultPageData.og,
        data: {
            ...defaultPageDataInner,
            ...(initialPageData ? initialPageData : {}),
            containerClass: defaultPageDataInner.containerClass,
        },
    });

    useEffect(() => {
        if (initialPageData) {
            setPageDataValue((prevPageData) => ({
                ...prevPageData,
                title: getFormattedPageTitle(initialPageData.title),
                description: initialPageData.description || prevPageData.description,
                og: initialPageData.og || prevPageData.og,
                data: {
                    ...prevPageData.data,
                    ...initialPageData,
                },
            }));
        }
    }, [initialPageData]);

    const setPage = (data: SetPageProps) => {
        const { og, ...innerData } = data;

        setPageDataValue((prevPageData) => {
            const updatedData = {
                ...prevPageData.data,
                ...innerData,
            };

            const formattedTitle = getFormattedPageTitle(innerData.title || prevPageData.data.title);

            return {
                ...prevPageData,
                title: formattedTitle,
                description: innerData.description || prevPageData.description,
                og: og ? { ...prevPageData.og, ...og } : prevPageData.og,
                data: updatedData,
            };
        });
    };

    const resetPage = () => {
        setPageDataValue(defaultPageData);
    };

    return (
        <PageContext.Provider
            value={{
                pageData,
                setPage,
                resetPage,
            }}
        >
            {children}
        </PageContext.Provider>
    );
};

export default PageProvider;