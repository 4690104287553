import { useDispatch } from 'react-redux';
import { useGlobalContext } from 'context/GlobalContext';
import { doLogout } from 'actions';
import { logout } from 'actions/AuthActions';
import { User } from 'types/User';

export const useAuthActions = () => {
    const dispatch = useDispatch();
    const { setUser } = useGlobalContext();
    
    const logoutUser = () => {
        // Clear Redux state
        dispatch(logout());
        dispatch(doLogout());
        
        // Clear context
        setUser({} as User);
        
        // Clear storage
        localStorage.removeItem('search_obj');
        localStorage.removeItem('live_auctions_search_obj');
    };
    
    return {
        logoutUser,
    };
};